import Keycloak from "keycloak-js";
import {KEYCLOAK_AUTH_SERVER_URL} from "./properties";

const _kc = new Keycloak({
    url: KEYCLOAK_AUTH_SERVER_URL,
    realm: 'SIMS',
    clientId: 'workbench-ui'
});

class Auth {

    init(onAuthenticatedCallback) {
        _kc.init({
            onLoad: 'login-required',
            pkceMethod: 'S256'
        })
            .then(onAuthenticatedCallback)
            .catch(console.error);
    }

    login = _kc.login;

    logout = _kc.logout;

    isAuthenticated() {
        return !!_kc.token;
    }

    headers() {
        return {Authorization: 'Bearer ' + _kc.token};
    }

    updateToken = (successCallback) =>
        _kc.updateToken(5)
            .then(successCallback)
            .catch(this.login);
}

export default new Auth();
