import thunkMiddleware from 'redux-thunk'
import {createBrowserHistory} from 'history'
import {applyMiddleware, compose, createStore} from 'redux'
import {reducers} from './reducers/combinedReducers'
import {routerMiddleware} from 'connected-react-router'

export const history = createBrowserHistory()


export default function configureStore(preloadedState) {
    return createStore(
        reducers(history), // root reducer with router state
        preloadedState,
        compose(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                thunkMiddleware
            ),
        ),
    )
}

