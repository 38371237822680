import React from "react";
import {Button, Dropdown} from "antd";
import {EllipsisOutlined} from "@ant-design/icons";

export function ActionsMenu({actions}) {

    return (<Dropdown overlay={actions}>
        <p style={{paddingTop: 5}} onClick={e => e.preventDefault()}>
            <Button><EllipsisOutlined /></Button>
        </p>
    </Dropdown>);

}
