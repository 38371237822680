import React from "react";
import {Line} from "react-chartjs-2";
import {labelMapper} from "../../../helpers";

export function InsuranceGraph ({graphData}) {

    const  getTransactionsChartData = () => {

        const tr = [...graphData]

        return {
            labels: tr.map((v,i) => v['holding_quantity']),
            datasets: [
                {
                    label: labelMapper('stockout_cost'),
                    data: tr.map((v,i) => v['stockout_cost']),
                    borderColor: 'blue',
                    backgroundColor: 'rgba(0, 255, 128, 0)',
                    fill: false,
                },
                {
                    label: labelMapper('holding_cost'),
                    data: tr.map((v,i) => v['holding_cost']),
                    backgroundColor: 'rgba(0, 255, 128, 0)',
                    borderColor: 'rgba(0, 255, 128, 0.2)',
                },
                {
                    label: labelMapper('total_cost'),
                    data: tr.map((v,i) => v['total_cost']),
                    fill: false,
                    backgroundColor: 'rgba(54, 162, 235,0)',
                    borderColor: 'rgba(255, 0, 0, 0.3)',
                },
            ]
        };

    }

    const options = {
        interaction: {
            intersect: false,
            axis: 'x'
        },
    };

    return <Line data={getTransactionsChartData()} options={options} />

}
