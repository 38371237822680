import React from 'react'
import { Bar } from 'react-chartjs-2';
import {properties} from "../../properties";

export function VerticalChart({colors, source}) {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: false,
            },
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'Percentage (%)',
                    color: properties.kbrColors.pantone301Blue
                },
            },
        },
    };

    const labels = ['Total',  'Processed', 'Finalised', 'Locked' ];

    const data = {
        labels,
        datasets: [
            {
                data: source.map(value => value),
                label: '%',
                backgroundColor: colors.map(color => color + '33'),
                borderColor: colors,
                borderWidth: 1,
            }
        ],
    };

    return (
        <Bar style={{height: 200}} options={options} data={data} />
    )

}
