import React from "react";
import {Dropdown, Menu} from "antd";
import {properties} from "../../../properties";
import {
    createDMLink, createEHLink,
    createIHLink,
    createKHLink,
    createMINMAxLink,
    createScenarioLink,
    openLink
} from "../../../helpers";
import {LeftCircleOutlined} from "@ant-design/icons";


export function Link ({href, children}) {
    return <a href={href} target="_blank" rel="noopener noreferrer" style={{color: properties.kbrColors.pantone301Blue}}>
        {children}
    </a>
}


export function MaterialBlock({material, children}) {

    const project_id = window.location.pathname.split('/')[2]

    const menuItems = <Menu>
        <Menu.Item>
            <Link href={createMINMAxLink(material, project_id, window.location.href )}>
                Workbench
            </Link>
        </Menu.Item>
        <Menu.Item>
            <Link href={createIHLink('Client', material)}>
                Inventory Hub
            </Link>
        </Menu.Item>
        <Menu.Item>
            <Link href={createKHLink('Client', material)}>
                Knowledge Hub
            </Link>
        </Menu.Item>
        <Menu.Item>
            <Link href={createDMLink('Client', material)}>
                Engineering Hub
            </Link>
        </Menu.Item>
        <Menu.Item>
            <Link href={createEHLink('Client', material)}>
                Equipment Hub
            </Link>
        </Menu.Item>
    </Menu>

    return <Dropdown overlay={menuItems}>
        <div className='beautiful-link'>{children}</div>
    </Dropdown>
}

export function GetBack({link}) {
    return  <LeftCircleOutlined color={properties.kbrColors.pantone301Blue}
                                style={{fontSize: 25}}
                                onClick={() => openLink(link)}/>
}

export function ScenarioLink ({id, name}) {
    const project_id = window.location.pathname.split('/')[2]
    return  <Link href={createScenarioLink(project_id, id )}>
        {name}
    </Link>
}
