import React, {Component} from "react";
import axios from "../../api";
import {getProjectId} from "../../helpers";

import * as Auth from '../../AuthService'
import {Card, Col, Row, Statistic, Table} from "antd";
import { Line } from 'react-chartjs-2';
import './settings.css'
import {DataCard} from "../helpers/cards/Card";

export class MinMaxSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            project: {},
            settings: {
                constants: {
                    service_factor_table: [],
                    default_service_level: {}
                }
            }
        }
    }

    getColumns() {

        const _default = this.state.settings.constants.default_service_level

        let columns = this.state.settings.constants.service_factor_table.map((v,i) => {
            let _cl = v.sl !== _default.sl ? "" : 'default-selection';
            return {
                title: v.sl,
                key: i,
                dataIndex:  i,
                className:  _cl,
                render(text) {
                    return {
                        props: {
                            className:  _cl,
                        },
                        children: <div>{text}</div>
                    };
                },
            }

        })

        columns = [{ title: 'Service Level', key:  'title',dataIndex:'title'}].concat(columns)

        return columns
    }

    parseDataSource = () => {
        let data = [{}]
        for (let i = 0; i < this.state.settings.constants.service_factor_table.length; i++ ) {
            data[0][i] = this.state.settings.constants.service_factor_table[i].csf
        }
        data[0]['title'] = 'Customer Service Factor'

        return data
    }

    componentDidMount() {
        const project_id = getProjectId(this.props.location)
        axios.get(`/projects/${project_id}/info`, Auth.createConfig()).then(json => this.setState({project: json.data}))
        axios.get(`/projects/${project_id}/settings`,Auth.createConfig()).then(json => this.setState({settings: json.data}))
    }

    getChartData = () => {
        const table = this.state.settings.constants.service_factor_table;

        return {
            labels: table.map(v => v['sl']),
            datasets: [
                {
                    label: 'Customer Service Factor',
                    data: table.map(v => v['csf']),
                    borderColor: '#00205c',
                    backgroundColor: 'rgba(0, 255, 128, 0)',
                    fill: false,
                },
            ]
        };
    }

    render() {

        const options = {
            scales: {
                x: {
                    type: 'linear',
                    min: 50,
                    max: 105
                }
            },
            interaction: {
                intersect: false,
                axis: 'x'
            },
        };

        return (
            <div className='settings-root'>
                <div className='text-center' style={{fontSize: 20}}>Main constants for the Project <b>{this.state.project.project_name}</b></div>
                <Row>
                    <Col span={20}>
                        <Card className="card-settings">
                            <Line height={75} data={this.getChartData()} options={options} />
                        </Card>
                    </Col>
                    <Col span={4}>
                        <Card className="card-settings">
                            <Statistic className='text-center' title='Default Price ($)' value={this.state.settings.constants.default_price}/>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <DataCard className="card-settings">
                            <Table style={{overflowX: 'hidden', padding: 0, margin:0}} size="small" columns={this.getColumns()} dataSource={this.parseDataSource()} pagination={false} />
                        </DataCard>
                    </Col>
                </Row>
            </div>
        );
    }

}
