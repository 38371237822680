import React from "react";
import {DataCard} from "./Card";
import {Graph} from "../Graph";
import {Divider} from "antd";
import {properties} from "../../../properties";

export function CardWithHistoryCharts({titles, graph1, graph2}) {

    return <DataCard title={titles[0]}>
        <h4>{titles[1]}</h4>
        <Graph data={graph1.data}
               colors={graph1.colors}
               titleLabels={graph1.columnNames}
               legendFields={graph1.legendFields}
               labels={graph1.labels}/>
        <Divider style={{backgroundColor: properties.kbrColors.pantone301Blue}}/>
        <h4>{titles[2]}</h4>
        <Graph data={graph2.data}
               titleLabels={graph2.columnNames}
               colors={graph2.colors}
               legendFields={graph2.legendFields}
               labels={graph2.labels}/>
    </DataCard>

}
