import {Col, Row, Statistic} from "antd";
import {DataCard} from "./Card";
import {VerticalChart} from "../VerticalChart";
import React from "react";
import {capitalizeFirstLetter} from "../../../helpers";

export function CardWithLineChart({height, cardTitle, data, total, colors}) {

    const prepareData = () => {
        let result = []
        for (const element of graphData) {
            result.push(element)
        }
        result.splice(0, 0, 100)
        return result;
    }

    const labels = data.map(v => capitalizeFirstLetter(v.title))
    labels.push('Not Processed')

    let graphData = data.map(v => v.percentage)

    let values = data.reverse()
    let percentage = graphData[graphData.length - 1]
    let last = <Statistic
        prefix={<div>({graphData[graphData.length - 2]}%) / </div>}
        suffix={<div>({percentage}%)</div>}
        valueStyle={{fontSize: 18}}
        value={values[0].value}/>

    for (let idx = 0; idx < values.length; idx++) {
        if (idx !== values.length - 1) {
            last = <Statistic
                prefix={graphData[graphData.length - 3 - idx] ? <div>({graphData[graphData.length - 3 - idx]}%) / </div> : '/'}
                suffix={last}
                valueStyle={{fontSize: 18}}
                value={values[idx + 1].value}/>
        } else {
            last = <Statistic className='text-center'
                              title={<div className='text-center'>Total / {labels[0]} / {labels[1]} / {labels[2]} </div>}
                              suffix={last}
                              valueStyle={{fontSize: 18}}
                              value={total}/>
        }

    }

    graphData = prepareData()

    return (<DataCard title={cardTitle} style={{margin: 10, minHeight: 240}}>
        <Row>
            <Col span={5}/>
            <Col span={15}>
                {last}
            </Col>
            <Col span={5}/>
        </Row>
        <Row style={{marginTop: 10}}>
            <Col span={4}/>
            <Col className='text-center' span={16}>
                <VerticalChart colors={colors} source={graphData}/>
            </Col>
            <Col span={4}/>
        </Row>
    </DataCard>)
}
