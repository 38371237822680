import React from "react";
import {properties} from "../../properties";
import {DataCard} from "./cards/Card";


export function TitleBlock({title}) {

    const color = properties.kbrColors.pantone301Blue

    return (
        <DataCard className='text-center'
              style={{backgroundColor: properties.kbrColors.lightBlue, color: color, borderColor: color}}>
            <h4>
                <b>{title}</b>
            </h4>
        </DataCard>

    )

}
