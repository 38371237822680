import React, {Component} from 'react';
import {Layout} from 'antd';
import {DashboardHeader} from "../../components/header/DashboardHeader";

export class LandingLayout extends Component {

  render() {

    return (
      <Layout  style={{minHeight:"100vh"}}>
        <DashboardHeader isLanding={true} />
        <Layout>
          <Layout>
          <Layout.Content style={{width: '100%', padding: 10, margin:"auto", backgroundColor: 'rgba(162,199,226, 0.05)'}}>
            {this.props.children}
          </Layout.Content>
          </Layout>
        </Layout>
      </Layout>
    ) 
  }
}
