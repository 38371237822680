import {Form, Input, InputNumber} from "antd";
import React, {useEffect, useState} from "react";
import {ModalWindow} from "./ModalWindow";
import {labelMapper} from "../../../helpers";
import {StandardForm} from "../forms/StandardForm";


export function TableModal({save, isModalVisible, closeModal, initialValues, full=false, fields}) {

    let [form] = Form.useForm()

    const handleCancel = () => {
        closeModal();
    };

    const onFinish = (values) => {
        initialValues.constants = {...values}
        save(initialValues)
    };

    useEffect(() => {
        if (isModalVisible) {

        } else {
            form.resetFields()
        }
    }, [isModalVisible])

    return <ModalWindow width={900} title={`Work with table rows`}
                        isModalVisible={isModalVisible}
                        scrollVisible={false}
                        onCancel={handleCancel}
                        modalProps={{
                            okText: "Update",
                            cancelText: "Cancel",
                            okButtonProps: {form: 'materialEditForm', key: 'submit', htmlType: 'submit'}
                        }}>
        <Form
            onFinish={onFinish}
            form={form}
            initialValues={initialValues.constants}
            id="materialEditForm"
        >
            <Form.Item
                label={labelMapper('production_output')}
                name="production_output"
                rules={[
                    {
                        required: true,
                        message: 'Please input the production output!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>
            {full && <Form.Item
                label={labelMapper('unitsPerDay')}
                name="unitsPerDay"
                rules={[
                    {
                        required: true,
                        message: 'Please input units per day!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>}
            {full && <Form.Item
                label={labelMapper('costPerUnit')}
                name="costPerUnit"
                rules={[
                    {
                        required: true,
                        message: 'Please input cost per unit!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>}
            <Form.Item
                label={labelMapper('loss')}
                name="loss"
                rules={[
                    {
                        required: true,
                        message: 'Please input the loss',
                    },
                ]}
            >
                <InputNumber/>
            </Form.Item>
        </Form>
    </ModalWindow>

}
