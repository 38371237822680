import {Switch, Route, Redirect} from 'react-router';
import PasswordReset from "./PasswordReset";
import PasswordCreate from "./PasswordCreate";


import React, {Component} from "react";
import ProjectPanel from "./components/panels/ProjectPanel";
import {RouteWithLayout} from "./pages/layouts/RouteWithLayout";
import {ProjectsLayout} from "./pages/layouts/ProjectsLayout";
import {ProjectLayout} from "./pages/layouts/ProjectLayout";
import Dashboard2 from "./Dashboard2";
import {MinMaxIterationPanel} from "./components/panels/MinMaxIterationPanel";
import {IterationOverview} from "./components/projects/IterationOverview";
import {MinMaxSettings} from "./components/settings/MinMaxSettings";
import {DataMaturityPanel} from "./pages/DataMaturityPanel";
import {BOMsPanel} from "./pages/BOMsPanel";
import {MinMaxMaterialPanel} from "./components/panels/MinMaxMaterialPanel";
import {PMCMPanel} from "./pages/PMCMPanel";
import {Commonality} from "./pages/Commonality";
import {DemoLayout} from "./pages/layouts/DemoLayout";
import {PicklesDemoDashboard} from "./pages/PicklesDemoDashboard";
import {DocumentAnalysis} from "./pages/DocumentAnalysis";
import DashboardCategoryLayout from "./pages/layouts/DashboardCategoryLayout";
import {LandingLayout} from "./pages/layouts/LandingLayout";
import {LandingPage} from "./pages/LandingPage";
import {InsuranceSettings} from "./components/settings/InsuranceSettings";
import {InsuranceItems} from "./pages/InsuranceItems";
import {InsuranceMaterialPage} from "./pages/InsuranceMaterialPage";

export class AHRouter extends Component {

    render() {
        return (
            <div>
            <Switch>
                <Route path='/password-change*'
                       render={props => <PasswordReset {...props}/>}
                />

                <Route path='/password-create*'
                       render={props => <PasswordCreate {...props} />}
                />

                <RouteWithLayout layout={ProjectsLayout} exact path="/projects" component={ProjectPanel} />
                <RouteWithLayout layout={LandingLayout} exact path="/landing" component={LandingPage} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/min-max/scenarios/*/overview" component={IterationOverview} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/overview" component={Dashboard2} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/settings" component={MinMaxSettings} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/ins-settings" component={InsuranceSettings} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/ins-calculation" component={InsuranceItems} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/min-max" component={MinMaxIterationPanel} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/min-max-material-analysis" component={MinMaxMaterialPanel} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/ins-calculation-material-analysis" component={InsuranceMaterialPage} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/boms" component={BOMsPanel} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/pm-cm-ratio" component={PMCMPanel} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/document-analysis" component={DocumentAnalysis} />
                <RouteWithLayout layout={ProjectLayout} path="/projects/*/commonality" component={Commonality} />
                <RouteWithLayout layout={DemoLayout} path="/projects/*/demo" component={PicklesDemoDashboard} />

                <RouteWithLayout layout={ProjectLayout}
                                 path="/projects/*/obsoletes"
                                 component={DashboardCategoryLayout}
                                 type={'obsoletes'}
                                 title={'Obsoletes'}
                />
                <RouteWithLayout layout={ProjectLayout}
                                 path="/projects/*/insurance"
                                 component={DashboardCategoryLayout}
                                 type={'insurance'}
                                 title={'Insurance'}
                />
                <RouteWithLayout layout={ProjectLayout}
                                 path="/projects/*/no-bom"
                                 component={DashboardCategoryLayout}
                                 type={'no-bom'}
                                 title={'No BOM'}
                />
                <RouteWithLayout layout={ProjectLayout}
                                 path="/projects/*/slow-moving"
                                 component={DashboardCategoryLayout}
                                 type={'slow-moving'}
                                 title={'Slow-Moving'}
                />

                <RouteWithLayout layout={ProjectLayout} path="/projects/*/data-maturity" component={DataMaturityPanel} />

                <Route exact path='/'
                       render={props => <Redirect to={'/projects'} {...props} />}
                />

                <Route path='/*'
                       render={props => <Redirect to={'/landing'} {...props} />}
                />
            </Switch>
            </div>
        );
    }

}
