import React, {Component} from 'react';
import {Layout, Menu} from 'antd';
import {DashboardHeader} from "../../components/header/DashboardHeader";
import api from "../../api";
import * as Auth from '../../AuthService'

export class ProjectLayout extends Component {

  constructor(props) {
    super(props);
    this.state = {
      _id: '',
      openKeys: [],
      navigation: [],
      baseUrl: ''
    }
  }

  getMenuItems() {
    const items = []
    for (const element of this.state.navigation) {
      if (element.children.length  > 0) {
        const url = element.link.startsWith('/') && this.state.baseUrl + this.state._id + element.link;
        items.push(<Menu.SubMenu style={{marginLeft: 0, color: 'black'}}
                                 className={url === this.props.location.pathname && 'ant-menu-item-selected'}
                                 title={!element.link.startsWith('/') ? element.name : <a style={{color: 'black'}} href={this.state.baseUrl + this.state._id + element.link}>{element.name}</a>}
                                 key={element.link}>
          {element.children.map(v => <Menu.Item key={v.link.split('/')[1]} >
            <a href={this.state.baseUrl + this.state._id + v.link}>{v.name}</a>
          </Menu.Item>)}
        </Menu.SubMenu>)
      } else {
        items.push(<Menu.Item key={element.link.split('/')[1]}>
          <a href={this.state.baseUrl + this.state._id + element.link}>{element.name}</a>
        </Menu.Item>)
      }
    }
    return items;
  }

  componentDidMount() {
    const _id = this.props.location.pathname.split('/')[2]
    this.setState({
      _id: _id
    })

    api.get(`/projects/${_id}/navigation`, Auth.createConfig())
        .then(json => this.setState({navigation: json.data.navigation, baseUrl: json.data.base_url}))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.state.navigation !== prevState.navigation) {
        let current = this.props.location.pathname.split('/')
        current = current[current.length - 1]
        for (const el of this.state.navigation) {
          const found = el.children && el.children.find(link => link.link.includes(current))
          if (el.link.includes(current) || found ) {
            this.setState({openKeys: [...this.state.openKeys, el.link]})
          }
        }
      }
  }

  onOpenKeysChange = (props) => {
    this.setState({openKeys: [...props]});
  };

  render() {
    let selectedMenuItem = this.props.location.pathname.split('/')[3]

    const items = this.getMenuItems();

    return (
      <Layout  style={{minHeight:"100vh"}}>
        <DashboardHeader />
        <Layout>
          <Layout>
            <Layout.Sider width={'20%'} theme='light'>
              <Menu theme="light" mode="inline" selectedKeys={[selectedMenuItem]} onOpenChange={this.onOpenKeysChange} openKeys={this.state.openKeys}>
                {items}
              </Menu>
            </Layout.Sider>
          <Layout.Content style={{width: '75%', backgroundColor: 'rgba(162,199,226, 0.05)'}}>
            {this.props.children}
          </Layout.Content>
          </Layout>
        </Layout>
      </Layout>
    ) 
  }
}
