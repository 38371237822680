import React from "react";
import {Modal} from "antd";
import {properties} from "../../../properties";


export function ModalWindow ({title, isModalVisible, onCancel, onOk, children, scrollVisible =true, modalProps}) {

    const headStyle = {
        borderColor: properties.kbrColors.pantone301Blue,
        color: properties.kbrColors.pantone301Blue
    }

    const bodyStyle = {
        height: '60vh',
        maxHeight: '60vh',
        overflowY: scrollVisible ? "scroll" : 'auto'
    }

    return <Modal  width={'40vw'}
                   getContainer={false}
                  title={<div style={headStyle}>{title}</div>}
                  open={isModalVisible}
                  onCancel={onCancel}
                  onOk={onOk}
                  bodyStyle={{bodyStyle, ...modalProps}}
                   {...modalProps}
    >
        {children}
    </Modal>
}
