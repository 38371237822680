import React, {Component} from "react";
import {ArrowUpOutlined, ArrowDownOutlined} from "@ant-design/icons";
import {Statistic} from "antd";

export class ImprovementStatistics extends Component {

    render() {
        return (
            <Statistic className='text-center'
                       title={this.props.title ? this.props.title : 'Improvement'}
                       value={Math.abs(this.props.value)}
                       valueStyle={{
                           color: this.props.value > 0 ? '#00783F' : '#95190C',
                       }}
                       prefix={this.props.value > 0 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>}
                       suffix="%"
            />
        );
    }

}

