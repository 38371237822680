import {DataCard} from "./Card";
import {Col, Row, Statistic} from "antd";
import {DoughnutGraph} from "../DoughnutGraph";
import React from "react";
import {capitalizeFirstLetter} from "../../../helpers";

export function CardWith2ParamsAndGraph({cardTitle,onCLick, showTitle=true, style, titles, value, labels, total, height, colors, improvement, full, doughnutHeight, getPercentageForSecondParam}) {
    const _sum = total ? total : value[titles[1]] + value[titles[0]]
    const percentage = Math.round(value[titles[0]] / _sum * 100) || 0
    const percentage2 = getPercentageForSecondParam ? `(${Math.round(value[titles[1]] / _sum * 100)}%)` : ''
    const prefix = value[titles[0]] !== percentage ? ` (${percentage}%) /` : '% /'

    const graphLabels = labels.map(v => v === 'Total' ? 'Rest': v)

    return (<DataCard title={cardTitle} style={{...style, margin: 10, height: height + 50}} onClick={onCLick}>
        <Row>
            <Col span={improvement ? 4 : 2}>{improvement}</Col>
            <Col className="text-center" span={improvement ? 18 : 20}>
                <Row>
                    <Col className="text-center" style={{margin: "auto"}}>
                        <Statistic className='text-center'
                                   valueStyle={{fontSize: 18}}
                                   title={showTitle && <div
                                       className='text-center'>{capitalizeFirstLetter(labels[0])} / {labels[1]}</div>}
                                   suffix={<Statistic suffix={percentage2} valueStyle={{fontSize: 18}}
                                                      prefix={prefix} value={value[titles[1]] || total}/>}
                                   value={value[titles[0]]}/>
                    </Col>
                </Row>
            </Col>
            <Col span={improvement ? 4 : 2}/>
        </Row>
        <Row style={{marginTop: 20}}>
            <Col span={4}/>
            <Col className='text-center' span={16} style={{margin: 'auto'}}>
                <DoughnutGraph data={[percentage, 100 - percentage]}
                               labels={[titles[0]]}
                               legendLabels={graphLabels}
                               full={full}
                               colors={colors}
                               height={doughnutHeight}
                               showLegend={false}/>
            </Col>
            <Col span={4}/>
        </Row>
    </DataCard>)
}
