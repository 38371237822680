import React from "react";
import {ModalWindow} from "./helpers/modals/ModalWindow";
import {Col, Row, Tree} from "antd";

function SearchTree ({treeData, setCheckedKeys, checkedKeys, onLoadData}) {

    return <Tree loadData={onLoadData}
                 checkable={true}
                 defaultExpandedKeys={['0']}
                 onCheck={setCheckedKeys}
                 checkedKeys={checkedKeys}
                 treeData={treeData} />;

}

export function SearchWithOptions ({title, isModalVisible, onCancel, onOk, project_id, onLoadData, treeData, setTreeData, checkedKeys, setCheckedKeys}) {

    const getTitle = (key, tree) => {
        let found;

        for (const element of tree) {
            const node = element;
            if (node.key === key) {
                found = {
                    title: node.title,
                    status: node.status
                }
            }

            else {
                if (node.children) {
                    if (node.children.some((item) => item.key === key)) {
                        let _found = node.children.find(item => item.key === key);
                        found = {
                            title: _found.title,
                            status: _found.status
                        }
                    } else if (getTitle(key, node.children)) {
                        found = getTitle(key, node.children);
                    }
                }
            }
        }

        return found;
    };

    const getParentTitle = (key, tree) => {
        let parentKey;

        for (const element of tree) {
            const node = element;

            if (node.children) {
                if (node.children.some((item) => item.key === key)) {
                    parentKey = node.title;
                } else if (getParentTitle(key, node.children)) {
                    parentKey = getParentTitle(key, node.children);
                }
            }
        }

        return parentKey;
    };

    return <ModalWindow title={title} isModalVisible={isModalVisible} onOk={onOk} onCancel={onCancel}>
        <Row>
            <Col span={15}>
                <SearchTree treeData={treeData}
                            project_id={project_id}
                            checkedKeys={checkedKeys}
                            setCheckedKeys={setCheckedKeys}
                            onLoadData={onLoadData}
                            setTreeData={setTreeData}/>
            </Col>
        </Row>
    </ModalWindow>

}
