import {combineReducers} from "redux";
import {
    SELECT_ITEMS,
    GET_DUPLICATES_INFO,
    GET_INSURANCE_INFO,
    GET_OBSOLETE_INFO,
    GET_SLOW_MOVING_INFO,
    SELECT, GET_NO_BOM_INFO,
    LAST_CATEGORY_SELECTED,
    GET_INVENTORY_INFO, FILTER_VALUES, SELECT_PROJECT, SELECTED_INFO, TOTAL_QUANTITY
} from "../actions/Constants";
import {connectRouter} from "connected-react-router";

export const reducers = (history) => {
    return combineReducers(
        {
            router: connectRouter(history),
            inventoryInfo: inventoryInfoReducer,
            duplicatesInfo: duplicatesInfoReducer,
            obsoleteInfo: obsoleteInfoReducer,
            insuranceInfo: insuranceInfoReducer,
            slowMovingInfo: slowMovingInfoReducer,
            noBOMInfo: noBOMInfoReducer,
            selected: getSelectedReducer,
            total: totalQuantityReducer,
            selectedInfo: selectedInfoReducer,
            selectedTitle: selectReducer,
            selectedProject: selectProjectReducer,
            lastCategorySelected: lastCategorySelectedReducer,
            filterValues: filterValuesReducer,
        }
    );

}

const selectedInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case SELECTED_INFO:
            return action.info;
        default:
            return state
    }
}

const inventoryInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_INVENTORY_INFO:
            return action.info;
        default:
            return state
    }
}

const duplicatesInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_DUPLICATES_INFO:
            return action.info;
        default:
            return state
    }
}

const obsoleteInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_OBSOLETE_INFO:
            return action.info;
        default:
            return state
    }
}

const insuranceInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_INSURANCE_INFO:
            return action.info;
        default:
            return state
    }
}

const slowMovingInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_SLOW_MOVING_INFO:
            return action.info;
        default:
            return state
    }
}

const noBOMInfoReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_NO_BOM_INFO:
            return action.info;
        default:
            return state
    }
}

const getSelectedReducer = (state = {}, action) => {
    switch (action.type) {
        case SELECT_ITEMS:
            return action.selected;
        default:
            return state
    }
}

const selectReducer = (state = "", action) => {
    switch (action.type) {
        case SELECT:
            return action.title;
        default:
            return state
    }
}

const selectProjectReducer = (state = "", action) => {
    switch (action.type) {
        case SELECT_PROJECT:
            return action.value;
        default:
            return state
    }
}

const lastCategorySelectedReducer = (state = "", action) => {
    switch (action.type) {
        case LAST_CATEGORY_SELECTED:
            return action.value;
        default:
            return state
    }
}

const totalQuantityReducer = (state = 0, action) => {
    switch (action.type) {
        case TOTAL_QUANTITY:
            return action.total;
        default:
            return state
    }
}

const filterValuesReducer = (state = {}, action) => {
    switch (action.type) {
        case FILTER_VALUES:
            return action.values;
        default:
            return state
    }
}
