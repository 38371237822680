import React, {Component} from "react";
import {Col, Row} from "antd";
import {getProjectId} from "../helpers";
import api from "../api";
import * as Auth from "../AuthService";
import {properties} from "../properties";
import {TitleBlock} from "../components/helpers/TitleBlock";
import {CardWith2ParamsAndGraph} from "../components/helpers/cards/CardWith2ParamsAndGraph";
import {CardWithHistoryCharts} from "../components/helpers/cards/CardWithHistoryCharts";
import {ImprovementStatistics} from "../components/helpers/ImprovementStatistics";

export class PMCMPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            results: [],
            last: {
                start: '',
                current: '',
                target: '',
                date: ''
            },
        }
    }

    getResults = () => {
        const project_id = getProjectId(this.props.location)
        api.get(`/projects/${project_id}/pmcm/results`, Auth.createConfig())
            .then(json => {
                this.setState({
                    results: json.data,
                    last: json.data[json.data.length-1]
                })
            })
    }

    prepareForGraph = field => {
        return this.state.results.map(v => {
            return {
                'start': v.start[field],
                'current': v.current[field],
                'target': v.target[field],
                date: v.date
            }
        })
    }

    componentDidMount() {
        this.getResults();
    }

    getImprovement = () => {
        if (this.state.results.length > 0 ) {
            return Math.round(this.state.last.current.pm / this.state.last.total * 100) - Math.round(this.state.results[0].current.pm / this.state.results[0].total * 100)
        }
        return 0
    }

    render() {

        const withBomsColors = [
            properties.kbrColors.yellow,
            properties.kbrColors.vividCeruleanBlue,
            properties.kbrColors.acajouMarron,
        ]

        const withoutBomsColors = [
            properties.kbrColors.yellow,
            properties.kbrColors.pantone301Blue,
            properties.kbrColors.acajouMarron,
        ]

        const legendFields = ['Start', 'Current', 'Target']
        const labels = ['start', 'current', 'target']

        return (
            <div>
                <Row>
                    <Col span={1} />
                    <Col span={12}>
                        <Row>
                            <Col span={24} style={{padding: 15}}>
                                <TitleBlock title={`Status on: ${this.state.last.date}`}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{padding: 5}}>
                                <Row>
                                    <Col span={12}>
                                        <CardWith2ParamsAndGraph colors={properties.startColors}
                                                                 height={260}
                                                                 doughnutHeight={100}
                                                                 showLegend={true}
                                                                 full={true}
                                                                 titles={['pm', 'cm']}
                                                                 labels={['PM', 'CM']}
                                                                 value={this.state.last.start}
                                                                 cardTitle={'Start Values'}
                                                                 getPercentageForSecondParam={true}/>
                                    </Col>
                                    <Col span={12}>
                                        <CardWith2ParamsAndGraph colors={properties.startColors}
                                                                 height={260}
                                                                 doughnutHeight={100}
                                                                 showLegend={true}
                                                                 full={true}
                                                                 titles={['pm', 'cm']}
                                                                 labels={['PM', 'CM']}
                                                                 value={this.state.last.target}
                                                                 cardTitle={'Target Values'}
                                                                 getPercentageForSecondParam={true}/>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <CardWith2ParamsAndGraph colors={properties.startColors}
                                                                 height={260}
                                                                 full={true}
                                                                 doughnutHeight={100}
                                                                 showLegend={true}
                                                                 titles={['pm', 'cm']}
                                                                 labels={['PM', 'CM']}
                                                                 improvement={<ImprovementStatistics value={this.getImprovement()}/>}
                                                                 value={this.state.last.current}
                                                                 cardTitle={'Current Values'}
                                                                 getPercentageForSecondParam={true}/>
                                    </Col>
                                </Row>
                                <Row>

                                </Row>
                            </Col>

                        </Row>
                    </Col>
                    <Col span={10} style={{padding: 10}}>
                        <CardWithHistoryCharts titles={['Overview', 'PM dynamics', 'CM dynamics']} graph1={{
                            data: this.prepareForGraph('pm'),
                            colors: withBomsColors,
                            columnNames: ['Dates', 'PMs', ],
                            legendFields,
                            labels
                        }} graph2={{
                            data: this.prepareForGraph('cm'),
                            colors: withoutBomsColors,
                            columnNames: ['Dates', 'CMs', ],
                            legendFields,
                            labels
                        }} />
                    </Col>
                </Row>
            </div>
        );
    }

}
