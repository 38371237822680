import React, {Component} from "react";
import {Button, Col, Divider, message, Row, Statistic, Table} from "antd";
import {DataCard} from "../helpers/cards/Card";
import {getProjectId, getURLParam} from "../../helpers";
import api from "../../api";
import {ModalWindow} from "../helpers/modals/ModalWindow";
import {GetBack, MaterialBlock, ScenarioLink} from "../helpers/cards/MaterialBlock";
import {EditOutlined, HistoryOutlined, LinkOutlined} from "@ant-design/icons";
import {EditingMaterialModal} from "../helpers/modals/EditingMaterialModal";
import {CurrentUserContext} from "../../contexts/AuthContext";
import {HistoryModal} from "../helpers/modals/HistoryModal";


export function CalcHelpModal({isModalVisible, setModalVisible, selected}) {

    if (Object.keys(selected).length === 0) {
        return null;
    }


    const constants = selected.iteration_info.constants;

    return <ModalWindow title={'Calculations Guide for Scenario: ' + selected.iteration_name}
                        isModalVisible={isModalVisible}
                        onOk={() => setModalVisible(false)}
                        scrollVisible={false}
                        onCancel={() => setModalVisible(false)}>
        <div>
            {!selected.approved && <div>
                <div> The formulas outlined below are used by the KBR SIMS Workbench standard to calculate MIN/MAX
                    levels for the item:
                </div>
                <div style={{marginTop: 20, marginBottom: 20}}>
                    <b>SP</b> - Scenario Parameter
                </div>
                <Divider/>
                <div><code><b>Monthly Demand</b> = Demand / 12 = {selected.demand} / 12 = {selected.demand_month}</code>
                </div>
                <div><code><b>MAD (Mean Absolute Deviation)</b> = {constants.mad} (SP) x monthly demand = 1
                    * {selected.demand_month} = {selected.demand_month}</code></div>
                <div><code><b>LT (Lead time in month)</b> = Lead Time in days / 30 = {selected.lt_days} / 30
                    = {selected.lt_month}</code></div>
                <Divider/>
                <div><code><b>SS (Safety Stock)</b> = CSF (SP) x MAD x SQRT(LT)
                    = {constants.csf} x {selected.demand_month} * SQRT({selected.lt_month}) = {selected.ss}</code></div>
                <div><code><b>DDLT (Demand during lead time)</b> = (LT x monthly demand)
                    = {selected.lt_month} * {selected.demand_month} = {selected.ddlt}</code></div>
                <Divider/>
                <div><code><b>NEW MIN</b> = SS + DDLT = ROUND({selected.ss} + {selected.ddlt}) =
                    ROUND({selected.ss + selected.ddlt}) = {selected.new_min}</code></div>
                <Divider/>
                <div><code><b>ROQ</b> = SQRT(( 2 x A (Demand) x S (Cost to place one order - SP)/ ( i (Holding cost -
                    SP) x $ (per unit price))</code></div>
                <div><code><b>ROQ</b> = SQRT( (2 x {selected.demand} x {constants.s}) / ({constants.holding * 100}%
                    x {selected.price}) ) = {selected.roq}</code></div>
                <Divider/>
                <div><code><b>NEW MAX</b> = MIN + ROQ
                    = {selected.new_min} + {selected.roq} = {selected.new_min + selected.roq} = {selected.new_max}
                </code></div>
            </div>}
            {selected.approved && <div>
                The MIN/MAXES were overridden manually!
                <Divider/>
                <div><code><b>NEW MIN</b> = {selected.new_min}</code></div>
                <Divider/>
                <div><code><b>NEW MAX</b> = {selected.new_max}</code></div>
            </div>}
        </div>
    </ModalWindow>
}


class AnalysisTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openModal: false,
            selected: {}
        }
    }


    columns = () => {

        return [
            {
                title: 'Scenario',
                dataIndex: 'iteration_name',
                key: 'iteration_name',
                render: (name, scenario) => {
                    return <ScenarioLink name={name} id={scenario._id}/>
                }
            },
            {
                title: 'Scope',
                dataIndex: 'scope',
                key: 'scope',
            },
            {
                title: 'Service Level',
                dataIndex: 'service_level',
                key: 'service_level',
            },
            {
                title: 'CSF',
                dataIndex: 'csf',
                key: 'csf',
            },
            {
                title: 'Holding',
                dataIndex: 'holding',
                key: 'holding',
            },
            {
                title: 'Cost to Place an order',
                dataIndex: 's',
                key: 's',
            },
            {
                title: 'Old MIN',
                dataIndex: 'rop',
                key: 'rop',
            },
            {
                title: 'New MIN',
                dataIndex: 'proposed_min',
                key: 'proposed_min',
            },
            {
                title: 'Change in Value (MIN) ($K)',
                dataIndex: 'min_value_change_K',
                key: 'min_value_change_K',
            },
            {
                title: 'Old MAX',
                dataIndex: 'max',
                key: 'max',
            },
            {
                title: 'New MAX',
                dataIndex: 'proposed_max',
                key: 'proposed_max',
            },
            {
                title: 'Change in Value (MAX) ($K)',
                dataIndex: 'max_value_change_K',
                key: 'max_value_change_K',
            },
            {
                title: '',
                dataIndex: 'new_max',
                key: 'new_max',
                render: (x, item) => <Button className='float-right'
                                             onClick={() => this.setState({openModal: true, selected: item})}>?</Button>
            },
        ]

    }

    getData = () => {
        return this.props.data.map((record, i) => {
            return {
                ...record,
                iteration_name: record.iteration_info.iteration_name,
                _id: record.iteration_info._id,
                csf: record.iteration_info.constants.csf,
                s: record.iteration_info.constants.s,
                service_level: record.iteration_info.constants.service_level,
                holding: <div>{record.iteration_info.constants.holding * 100}%</div>,
                scope: record.iteration_info.scope.label,
                "proposed_min": <div
                    style={{color: (record['new_min'] - record['rop'] > 0) ? 'red' : 'green'}}>{record['new_min']}</div>,
                "proposed_max": <div
                    style={{color: (record['new_max'] - record['max'] > 0) ? 'red' : 'green'}}>{record['new_max']}</div>,
                "min_value_change_K": <div
                    style={{color: (record['min_value_change_K'] < 0) ? 'red' : 'green'}}>{Math.round(record['min_value_change_K'] / 1000 * 100) / 100}</div>,
                "min_value_diff": record['min_value_change_K'],
                "max_value_diff": record['max_value_change_K'],
                "max_value_change_K": <div
                    style={{color: (record['max_value_change_K'] < 0) ? 'red' : 'green'}}>{Math.round(record['max_value_change_K'] / 1000 * 100) / 100}</div>,
                'key': i,
                'prettyPrice': <Statistic className='text-center stat-price' value={record['price']}/>

            }
        })
    }

    render() {
        return (
            <div>
                <Table dataSource={this.getData()}
                       columns={this.columns()}/>
                <CalcHelpModal isModalVisible={this.state.openModal}
                               selected={this.state.selected}
                               setModalVisible={(value) => this.setState({openModal: value, selected: {}})}/>
            </div>
        );
    }

}

export class MinMaxMaterialPanel extends Component {

    constructor(props) {
        const projectId = getProjectId(props.location);

        const material = getURLParam('material')
        const from = getURLParam('from')
        super(props);
        this.state = {
            projectId: projectId,
            material: material,
            editModal: false,
            historyModal: false,
            from: from,
            data: [],
            itemInfo: {
                description: '',
                rop: '',
                max: '',
                Type: ''
            }
        }
    }

    getAnalysis() {
        api.get(`/projects/${this.state.projectId}/material-analysis/${this.state.material}`)
            .then(json => {
                if (json.data.scenarios.length === 0) {
                    message.warn(`Material ${this.state.material} is not included in any of existing scenarios!`)
                }
                this.setState({data: json.data.scenarios, itemInfo: json.data.itemInfo})
            })
    }

    componentDidMount() {
        this.getAnalysis()
    }

    getDiff = values => {
        const diff = []
        for (let key of Object.keys(values)) {
            if (this.state.itemInfo.hasOwnProperty(key)) {
                if (key === 'approved') {
                    if (this.state.itemInfo['approved'] === 'NA' && values[key]) {
                        diff.push({
                            key,
                            old: false,
                            new: true
                        })
                    } else if (this.state.itemInfo[key] !== values[key]) {
                        diff.push({
                            key,
                            old: this.state.itemInfo[key],
                            new: values[key]
                        })
                    }
                } else if (this.state.itemInfo[key] !== values[key]) {
                    diff.push({
                        key,
                        old: this.state.itemInfo[key],
                        new: values[key]
                    })
                }
            } else {
                if (['approved', 'comment'].indexOf(key) > -1) {
                    continue
                }
                diff.push({
                    key,
                    old: 'NA',
                    new: values[key]
                })
            }
        }
        return diff;
    }

    removeKeys = (values) => {
        let data = {...values}
        delete data["appr_min"]
        delete data["appr_max"]
        return data
    }

    save = (values, form) => {
        const diff = this.getDiff(values)
        if (diff.length === 0) {
            this.close()
        }
        if (!values.approved) {
            values = this.removeKeys(values)
        }
        const data = {
            values: {...values},

            add_info: {
                user: this.context.email(),
                material: this.state.material,
                date: new Date(),
                comment: values.comment,
                changed: diff
            }
        }

        message.info('Saving...')
        api.post(`/projects/${this.state.projectId}/materials/${this.state.itemInfo._id}/save`, data)
            .then(() => {
                message.success('Your changes were saved')
                this.setState({editModal: false})
                this.getAnalysis()
            }).catch(() => message.error('Something went wrong during saving. Please try again later.'))
    }

    close = () => {
        this.setState({historyModal: false})
    }

    render() {


        return (
            <div>
                <Row style={{padding: 15}}>
                    <Col span={4}>
                        <GetBack link={this.state.from}/>
                    </Col>

                    <Col span={16}>
                        <DataCard title={<Row>
                            <Col span={20}>
                                <Row>
                                    <div style={{marginRight: 5}}>Material:</div>
                                    <MaterialBlock material={this.state.material} children={<div>{this.state.material} <LinkOutlined /></div>}/>
                                    <EditOutlined style={{marginLeft: 5, marginTop:5}}
                                                  onClick={() => this.setState({editModal: true})}/>

                                </Row>
                            </Col>
                            <Col span={4}>

                                <span className='url-link float-right' style={{marginLeft: 10}}
                                      onClick={() => this.setState({historyModal: true})}>History <HistoryOutlined/></span>
                            </Col>
                        </Row>}>
                            {this.state.itemInfo.approved &&
                                <div style={{color: "green"}}>The MIN/MAXES were overridden manually!</div>}
                            {this.state.itemInfo.Type === 'Slow Moving' &&
                                <div style={{color: "red"}}>Material {this.state.material} is not included in any of
                                    existing scenarios!</div>}
                            Description: {this.state.itemInfo.description}
                            <Row>
                                <Col span={5}><Statistic title={'Lead Time (Days)'}
                                                         value={this.state.itemInfo.lt_days}/> </Col>
                                <Col span={5}><Statistic title={'Number Of BOMs'}
                                                         value={this.state.itemInfo['EQ BOM Count']}/> </Col>
                                <Col span={5}><Statistic title={'Annual Demand'} value={this.state.itemInfo.demand}/>
                                </Col>
                                <Col span={5}><Statistic title={'Price per Unit'} value={this.state.itemInfo.price}
                                                         suffix={'$'}/> </Col>
                                <Col span={4}><Statistic title={'Type'}
                                                         formatter={value => value}
                                                         value={this.state.itemInfo.Type ? this.state.itemInfo.Type : ''}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={8}><Statistic title={'Highest Criticality'}
                                                         value={this.state.itemInfo.criticality}/> </Col>
                                <Col span={8}><Statistic title={'PMP\'s Associated'} value={this.state.itemInfo.pms}/>
                                </Col>
                                <Col span={8}><Statistic title={'Quantity Installed'}
                                                         value={this.state.itemInfo.installed}/> </Col>
                            </Row>
                        </DataCard>
                    </Col>
                    <Col span={48}/>
                </Row>
                <Row style={{padding: 15}}>
                    <Col span={24}>
                        <AnalysisTable data={this.state.data}/>
                    </Col>
                </Row>
                <EditingMaterialModal item={this.state.itemInfo} isModalVisible={this.state.editModal}
                                      closeModal={() => this.setState({editModal: false})} save={this.save}/>
                <HistoryModal modalProps={{height: '60vh', maxHeight: '60vh', overflowY: 'auto'}}
                              _id={this.state.itemInfo._id} project_id={this.state.projectId}
                              isModalVisible={this.state.historyModal} onCancel={this.close} onOk={this.close}/>

            </div>
        );
    }

}

MinMaxMaterialPanel.contextType = CurrentUserContext;
