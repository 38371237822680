import React, {Component} from "react";
import {Button, Col, message, Row, Table} from "antd";
import * as Auth from "../AuthService";
import api from "../api";
import {downloadExcel, getProjectId} from "../helpers";
import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {properties} from "../properties";
import {DataCard} from "../components/helpers/cards/Card";
import {MaterialBlock} from "../components/helpers/cards/MaterialBlock";
import {SearchComponent} from "../components/helpers/TablePanel";
import {StatPanel} from "./layouts/DashboardCategoryLayout";

export class Commonality extends Component {

    constructor(props) {
        super(props);
        this.state = {
            commonality: [],
            defaultTree: [],
            expandedKeys: [],
            searchValue: '',
            expandedRowKeys: [],
            selected: '',
            example: [],
            stat: {
                start: 0,
                current: 0,
                total: 0
            },
            selectedColor: properties.kbrColors.acajouMarron + '1a',
            autoExpandParent: true,
            query: ''
        }
    }

    getStat = () => {
        api.get(`/dashboard/duplicates/overview-info`, Auth.createConfig())
            .then(json => {
                this.setState({stat: json.data})
            })
    }

    componentDidMount() {
        this.getStat()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.selected !== this.state.selected) {
            this.getCommonality(this.state.selected)
        }
    }

    getCommonality(type) {
        const project_id = getProjectId(this.props.location)
        api.get(`/projects/${project_id}/commonality?type=${type}`, Auth.createConfig())
            .then(json => {
                this.setState({commonality: json.data, query: ''})
            })
    }

    search_commonality(query) {
        if (query) {
            const project_id = getProjectId(this.props.location)
            api.get(`/projects/${project_id}/commonality/search?type=${this.state.selected}&query=${query}`, Auth.createConfig())
                .then(json => {
                    if (json.data.length > 0) {
                        this.setState({commonality: json.data, query: query})
                    } else {
                        message.warn(`The material ${query} was not found in the database or was not marked as a duplicate`)
                    }
                })
        } else {
            this.getCommonality(this.state.selected)
        }
    }


    getExamples = (partNumber) => {
        const project_id = getProjectId(this.props.location)
        api.get(`/projects/${project_id}/commonality/example?type=${this.state.selected}&pn=${partNumber}`, Auth.createConfig())
            .then(json =>  this.setState({example: json.data}))

    }

    getNestedTable = () => {
        const columns = [
            {key: 'material', dataIndex: 'material', 'title': 'Material', render: material => <MaterialBlock material={material}>{material}</MaterialBlock>},
            {key: 'description', dataIndex: 'description', 'title': 'Description'},
            {key: 'part_number_original', dataIndex: 'part_number_original', 'title': 'Part Number Original'},
            {key: 'part_number_matched', dataIndex: 'part_number_matched', 'title': 'Part Number Matched'}
        ]

        return <Table className="nested-table-commonality"
                      bordered={true}
                      columns={columns}
                      dataSource={this.state.example} />
    }

    onTableRowExpand = (expanded, record) => {
        let keys = [];
        if(expanded){
            keys.push(record.key);
        }
        this.setState({expandedRowKeys: keys});
    }

    exportCommonality = () => {
        message.info("Start exporting the commonality report...")
        const project_id = window.location.href.split('/').reverse()[1]
        const config = Auth.createConfig();
        config['responseType'] = 'blob';
        api.get(`/projects/${project_id}/commonality/export/full`, config)
            .then(json => {
                downloadExcel(json.data, `Commonality Analysis.xlsx`)
            }).catch(error => {
            console.log(error)
        })
    }


    render() {

        const columns = [
            {
                key: 'part_number_matched',
                dataIndex: 'part_number_matched',
                title: 'Matched Part Number'
            },
            {
                key: 'material',
                dataIndex: 'material',
                title: 'Count of Materials'
            },
            {
                key: 'Actions',
                title: 'Actions',
            }
        ]

        const data = this.state.commonality.map(v => {
            return {
                ...v,
                key: v.part_number_matched
            }
        })



        return (
                <div>
                    <StatPanel type={'duplicates'} onClick={() =>  this.setState({selected: 'start'})} data={this.state.stat} title={'Duplicates'} />

                    {this.state.commonality.length > 0 && <DataCard style={{height: '60vh', margin: 10, padding: 0}}>
                        <Row>
                            <Col span={3} >
                                <Button style={{float: 'right',marginLeft: 5, marginTop: -20, marginBottom:10}} onClick={() => this.exportCommonality()} className='button'>Export Full Report</Button>
                            </Col>
                            <Col span={21}>
                                <SearchComponent search={(query) => this.search_commonality(query)}/>
                            </Col>
                        </Row>
                        {this.state.query && <div>Current query: <b>{this.state.query}</b> is present in <b>{this.state.commonality.length}</b> match (-es) </div>}
                        <Table columns={columns}
                                style={{margin:0, padding: 0}}
                                onHover={() => null}
                                scroll={{y: '38vh'}}
                                expandable={{
                                    expandIconColumnIndex:2,
                                    expandedRowRender: (record) => <div style={{margin:-15, padding: 5}}>{this.getNestedTable(record.part_number_matched)}</div>,
                                    expandIcon:({ expanded, onExpand, record }) =>
                                    expanded ? (
                                    <UpOutlined onClick={(e) => onExpand(record, e)}/>
                                    ) : (
                                    <DownOutlined onClick={(e) => onExpand(record, e)}/>
                                    ),
                                    onExpand: (expanded, record) => {
                                        this.onTableRowExpand(expanded, record)
                                        this.getExamples(record.part_number_matched)
                                    },
                                    expandedRowKeys: this.state.expandedRowKeys
                                }
                                }
                                dataSource={data}/>
                    </DataCard>}
                </div>

        );
    }

}
