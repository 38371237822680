import React from 'react';
import {Card} from "antd";

export function DataCard ({title,style, children, onClick, className}) {

    return (
        <Card className={`${className} card-settings`} onClick={onClick} title={title} style={{color: 'black', ...style}}>
            {children}
        </Card>
    )

}
