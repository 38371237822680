import React, {Component} from 'react';
import {Layout, Menu} from 'antd';
import {DashboardHeader} from "../../components/header/DashboardHeader";
import api from "../../api";
import * as Auth from '../../AuthService'

export class DemoLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            _id: '',
            openKeys: [],
            navigation: [],
            baseUrl: ''
        }
    }

    getMenuItems() {
        const items = []
        for (const element of this.state.navigation) {
                items.push(<Menu.Item key={element.link.split('/')[1]}>
                    {element.link ? <a href={element.link}>{element.name}</a> : <span>{element.name}</span>}
                </Menu.Item>)
        }
        return items;
    }

    componentDidMount() {
        const _id = this.props.location.pathname.split('/')[2]
        this.setState({
            _id: _id
        })

        api.get(`/projects/${_id}/navigation`, Auth.createConfig())
            .then(json => this.setState({navigation: json.data.navigation, baseUrl: json.data.base_url}))
    }

    render() {
        let selectedMenuItem = this.props.location.pathname.split('/')[3]

        const items = this.getMenuItems();

        return (
            <Layout  style={{minHeight:"100vh"}}>
                <DashboardHeader />
                <Layout>
                    <Layout>
                        <Layout.Sider width={'20%'} theme='light'>
                            <Menu theme="light" mode="inline" selectedKeys={[selectedMenuItem]} onOpenChange={this.onOpenKeysChange} openKeys={this.state.openKeys}>
                                {items}
                            </Menu>
                        </Layout.Sider>
                        <Layout.Content style={{width: '75%', backgroundColor: 'rgba(162,199,226, 0.05)'}}>
                            {this.props.children}
                        </Layout.Content>
                    </Layout>
                </Layout>
            </Layout>
        )
    }
}
