import React, {Component} from "react";
import axios from "../../api";
import {Button, Menu as DropdownMenu, message, Statistic, Tabs} from "antd";
import {connect} from "react-redux";
import {selectProject} from "../../actions/actions";
import {MinMaxModal} from "./MinMaxModal";
import {downloadExcel, getProjectId, openLinkInNewTab} from "../../helpers";
import * as Auth from '../../AuthService'
import "../helpers.css"
import {MaterialsSearch} from "../../pages/MaterialsSearch";
import {StandardTable} from "../helpers/TablePanel";
import {ActionsMenu} from "../helpers/ActionsMenu";
import {HelpModal} from "../helpers/modals/HelpModal";
import {InfoModal} from "../helpers/modals/InfoModal";
import {ScenarioLink} from "../helpers/cards/MaterialBlock";

const { TabPane } = Tabs;

export class IterationExpand extends Component {

    render() {
        return (
            <div>



            </div>
        );
    }

}

export class MinMaxIterationPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            iterations: [],
            openModal: false,
            openInfoModal: false,
            scopes: [],
            iteration: {
                constants: {

                }
            },
            loading: false,
            newPagination: {
                current: 1,
                pageSize: 10
            },
            total: 0,
            _filters: {}
        }
    }

    getIterations(newPagination = this.state.newPagination, _filters = this.state._filters) {
        this.setState({loading: true})
        const project_id = getProjectId(this.props.location)
        const pagination = {
            current: newPagination.current,
            size: newPagination.pageSize
        }

        if (newPagination.pageSize !== this.state.newPagination.pageSize) {
            pagination.current = 1
        }
        const data = {
            pagination,
            _filters

        }
        this.setState({projectId: project_id, })
        axios.post(`/projects/${project_id}/iterations`,  data, Auth.createConfig())
            .then(json => this.setState({iterations: json.data.iterations, projectId: project_id, newPagination: newPagination, _filters: _filters, total: json.data.total}))
            .finally(() => this.setState({loading: false}))
    }

    onChange = (newPagination, _filters, _sorter) => {
        this.getIterations(newPagination, _filters)
    }


    componentDidMount() {
        this.getIterations()
        let p = window.location.search;
        const params = new URLSearchParams(p);
        const from = params.get('search')
        if (from) {
            this.setState({activeKey: "2"})
        }
        axios.get(`/projects/${this.props.projectId}/iterations/scopes`, Auth.createConfig()).then(json => this.setState({scopes: json.data}))

    }

    createActionsMenu(iteration) {
        const project_id = getProjectId(this.props.location)
        return <DropdownMenu>
            <DropdownMenu.Item>
                <span className='url-link' onClick={() => openLinkInNewTab(`/projects/${project_id}/min-max/scenarios/${iteration._id}/overview`)}>Open</span>
            </DropdownMenu.Item>
            <DropdownMenu.Item>
                <span className='url-link' onClick={() => this.rerunIteration(iteration._id)}>Re-run iteration</span>
            </DropdownMenu.Item>
            <DropdownMenu.Item>
                <span className='url-link' onClick={() => this.exportIteration(iteration._id, iteration.iteration_name)}>Export</span>
            </DropdownMenu.Item>
            <DropdownMenu.Item>
                <span className='url-link' onClick={() => this.deleteIteration(iteration._id)}>Delete</span>
            </DropdownMenu.Item>
        </DropdownMenu>
    }

    getColumns = () => {
        const valueStyle= {fontSize: 16}
        return [
            {
                title: 'Scenario Name',
                dataIndex: 'iteration_name',
                key: 'iteration_name',
                align: 'left',
                render: (name, scenario) =>  <ScenarioLink name={name} id={scenario._id} />

            },
            {
                title: 'Scenario Scope',
                dataIndex: 'scope',
                key: 'scope',
                filters: this.state.scopes.map(v=> {
                    return {
                        value: v.label,
                        text: v.label
                    }
                })
            },
            {
                title: 'Materials Analyzed',
                dataIndex: 'analyzed',
                key: 'analyzed',
                align: 'center',
                render: (name, item) =>  <Statistic className='text-center' valueStyle={valueStyle} value={item.results.items_affected}/>
            },
            {
                title: 'Recommended Sell',
                dataIndex: 'sell',
                key: 'sell',
                align: 'center',
                render: (name, item) =>  <Statistic  className={`text-center ${item.results.can_sell_beautified.includes('-') ? 'stat-negative-value' : 'stat-positive-value'}`}
                                                     valueStyle={valueStyle}
                                                     value={item.results.can_sell_beautified}/>
            },
            {
                title: 'Recommended Buy',
                dataIndex: 'buy',
                key: 'buy',
                align: 'center',
                render: (name, item) =>  <Statistic className={`text-center ${item.results.should_buy_beautified.includes('-') ? 'stat-negative-value' : 'stat-positive-value'}`}
                                                    valueStyle={valueStyle}
                                                    value={item.results.should_buy_beautified}/>
            },
            {
                title: 'Params',
                dataIndex: 'info',
                key: 'info',
                width: 100,
                align: 'center',
                render: (name, item) => <p style={{paddingTop: 5}}><Button onClick={() => this.setState({openInfoModal: true, iteration: item, padding: 5},)}>?</Button></p>
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
                align: 'center',
                width: 100,
                render: (name, item) => <ActionsMenu actions={this.createActionsMenu(item)}/>
            }
        ];
    }

    deleteIteration(iterationId) {
        const project_id = getProjectId(this.props.location)
        axios.get(`/projects/${project_id}/iterations/${iterationId}/delete`, Auth.createConfig()).then(json => {
            alert(json.data)
            this.getIterations()
        })

    }

    rerunIteration(iterationId) {
        const project_id = getProjectId(this.props.location)
        message.info('Start re-running the iteration')
        axios.post(`/projects/${project_id}/iterations/${iterationId}/min-max?page=${1}&size=${10}`, {
            _search: {},
            _filters: {},
            sorter: {}
        }, Auth.createConfig())
            .then(() => {
                message.success('Done!')
                this.getIterations()
            }).catch(error => {
            message.error('Something went wrong! Please try again later')
        })

    }

    exportIteration = (iteration_id, iteration_name) => {
        message.info('Starting the export of ' + iteration_name)
        const project_id = this.props.location.pathname.split('/')[2]
        const config = Auth.createConfig();
        config['responseType'] = 'blob';
        axios.get(`/projects/${project_id}/iterations/${iteration_id}/min-max/export`, config)
            .then(json => {
                downloadExcel(json.data, `Proposed MIN-MAX ${iteration_name}.xlsx`)
            }).catch(error => {
            console.log(error)
        })
    }

    parseDataSource = () => {
        return this.state.iterations.map(iteration => {
            return {
                key: iteration._id,
                _id: iteration._id,
                scope: iteration.scope.label,
                iteration_name: iteration.iteration_name,
                constants: iteration.constants,
                results: iteration.results,
            }
        })
    }

    render() {

        const project_id = getProjectId(this.props.location)

        const setActiveKey = key => {
            this.setState({activeKey: key})
        }

        return (
            <div style={{padding: 24}}>
                <Tabs defaultActiveKey={"1"} activeKey={this.state.activeKey} onTabClick={setActiveKey}>
                    <TabPane tab={'Scenarios'} key={"1"}>
                <Button className='float-right' style={{marginLeft: 10}} onClick={() => this.setState({helpModal: true})}>?</Button>
                <Button className='float-right' onClick={() => this.setState({openModal: true})}>Add New Scenario</Button>
                <StandardTable data={this.parseDataSource()}
                               scroll={800}
                               loading={this.state.loading}
                               total={this.state.total}
                               onChange={this.onChange}
                               pageSize={this.state.newPagination.pageSize}
                               currentPage={this.state.newPagination.current}
                               tableProps={{}}

                       columns={this.getColumns()} />
                <MinMaxModal modalState={this.state.openModal}
                             scopes={this.state.scopes}
                             projectId={this.state.projectId}
                             closeModal={() => {
                                 this.setState({openModal: false})
                                 this.getIterations()
                             }}/>
                <HelpModal isModalVisible={this.state.helpModal} setModalVisible={(value) => this.setState({helpModal: value})} />
                <InfoModal isModalVisible={this.state.openInfoModal} iteration={this.state.iteration} setModalVisible={(value) => this.setState({openInfoModal: value})} />
                    </TabPane>
                    <TabPane tab={'Materials'} key={"2"}>
                        <MaterialsSearch projectId={project_id} />
                    </TabPane>
                </Tabs>
            </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        inventoryInfo: state.inventoryInfo,
        selectedProject: state.selectedProject
    }
}

export default connect(mapStateToProps, {
    selectProject
})(MinMaxIterationPanel)
