import React, {useEffect, useState} from "react";
import {ModalWindow} from "./ModalWindow";
import api from "../../../api";
import {Comment, Divider, Spin, Timeline} from "antd";
import {UserOutlined} from "@ant-design/icons";
import {properties} from "../../../properties";

export function HistoryModal ({isModalVisible, onOk, onCancel, _id, project_id, modalProps}) {

    const [history, setHistory] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (isModalVisible) {
            setLoading(true)
            api.get(`/projects/${project_id}/materials/${_id}/history`).then(json => {
                setHistory(json.data)
            }).finally(() => setLoading(false))
        }
    }, [isModalVisible])

    const beautifyDate = (date) => {
        return new Date(date).toLocaleString()
    }

    const fieldMapping = field => {
        switch (field) {
            case "price": return "Price per Unit ($)"
            case "lt_days": return "Lead Time (days)"
            case "demand": return "Annual Demand"
            case 'appr_max': return 'Approved Max'
            case 'appr_min': return 'Approved Min'
            default: return field
        }
    }

    const fixApprovedValues = (key, value) => {
        if (key !== 'approved') {
            return value;
        }
        if (value) {
            return 'Approved'
        } else {
            return 'Not Approved'
        }
    }

    const beautifyChanged = changed => {
        return changed.map(change => <p>{fieldMapping(change.key)}: From {fixApprovedValues(change.key, change.old)} -> To {fixApprovedValues(change.key, change.new)}</p>)
    }

    const createTimeline = () => {
        return history.map(action => <Timeline.Item label={beautifyDate(action.date)} color={properties.kbrColors.pantone301Blue}>
            <Comment
                author={action.user}
                avatar={<UserOutlined style={{fontSize: 24}}/>}
                content={<div>{action.comment}
                    <Divider style={{backgroundColor: properties.kbrColors.vividCeruleanBlue, margin: "10px 0"}} />
                    Change:
                    {beautifyChanged(action.changed)}
            </div>}
            />
        </Timeline.Item>)
    }

    const props = {
        ...modalProps,
        width: "90vw",
        cancelButtonProps: { style: { display: 'none' } }
    }

    return <ModalWindow modalProps={props} isModalVisible={isModalVisible} title={"History of changes"} onCancel={onCancel} onOk={onOk}>
        <Spin spinning={loading}>
        <Timeline style={{marginLeft: '-50vw'}} mode="left" reverse={true}>
            {createTimeline()}
        </Timeline>
        </Spin>
    </ModalWindow>

}
