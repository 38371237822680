import React, {useEffect} from 'react';
import {Form, Input,InputNumber} from "antd";


export function StandardForm({form, fields, onFinish, initialValues}) {

    const getInputByType = _type => {
        switch (_type) {
            case 'number': return <InputNumber style={{width: '50%'}} />
            default: return <Input />
        }
    }

    return <Form
            onFinish={onFinish}
            form={form}
            initialValues={{...initialValues}}
            id="editForm"
        >
            {fields.map ((v,i) => <Form.Item
                key={i}
                label={<b>{v.label}</b>}
                name={v.name}
                rules={[
                    {
                        required: v.required,
                        message: v.message,
                    },
                ]}
            >
                {getInputByType(v._type)}
            </Form.Item>)}
        </Form>

}
