import React from 'react'
import {CardWith2ParamsAndGraph} from "../components/helpers/cards/CardWith2ParamsAndGraph";
import {properties} from "../properties";
import {Col, Descriptions, Row, Statistic} from "antd";
import {DataCard} from "../components/helpers/cards/Card";
import {capitalizeFirstLetter} from "../helpers";

export function PicklesDemoDashboard ({}) {

    return <div>
        <Row style={{padding: 5}}>
            <Col span={8} />
            <Col span={8} >
                <DataCard style={{margin: 10}} title={<b>Financial Summary</b>}>
                    <Descriptions>
                        <Descriptions.Item span={24} label={<b>Estimated Gross Sales</b>}><Statistic valueStyle={{fontSize: 14}} value={984000} prefix={'AUD'} /> </Descriptions.Item>
                        <Descriptions.Item span={24} label={<b>Reuse/Repurposing Gross Sales</b>}><Statistic valueStyle={{fontSize: 14}} value={128450} prefix={'AUD'} /> </Descriptions.Item>
                        <Descriptions.Item span={24} label={<b>Recycling Gross Sales</b>}><Statistic valueStyle={{fontSize: 14}} value={855550} prefix={'AUD'} /> </Descriptions.Item>
                        <Descriptions.Item span={24} label={<b>Estimated Project Cost</b>}><Statistic valueStyle={{fontSize: 14}} value={285000} prefix={'AUD'} /> </Descriptions.Item>
                    </Descriptions>
                </DataCard>
            </Col>
            <Col span={8} />
        </Row>
        <Row>
            <Col span={8}>
            <CardWith2ParamsAndGraph colors={properties.startColors}
                                     height={200}
                                     doughnutHeight={90}
                                     showLegend={false}
                                     showTitle={false}
                                     titles={['current', 'Total']}
                                     labels={['current', 'Total']}
                                     value={{current: 81}}
                                     cardTitle={<b>Carbon Reduction Forecast</b>}
                                     total={100}/>
            </Col>
            <Col span={8}>
                <CardWith2ParamsAndGraph colors={properties.startColors}
                                         height={200}
                                         doughnutHeight={90}
                                         showLegend={false}
                                         showTitle={false}
                                         titles={['current', 'Total']}
                                         labels={['current', 'Total']}
                                         value={{current: 81}}
                                         cardTitle={<b>Cost Neutral Forecast</b>}
                                         total={100}/>
            </Col>
            <Col span={8}>
                <CardWith2ParamsAndGraph colors={properties.startColors}
                                         height={200}
                                         doughnutHeight={90}
                                         showLegend={false}
                                         showTitle={false}
                                         titles={['current', 'Total']}
                                         labels={['current', 'Total']}
                                         value={{current: 81}}
                                         cardTitle={<b>Project Specific Targets</b>}
                                         total={100}/>
            </Col>
        </Row>
        <Row style={{padding: 5}}>
            <Col span={8} >
                <DataCard style={{margin: 10}}>
                    <Row>
                        <Col className="text-center" style={{margin: "auto"}}>
                            <b>ESG</b>
                        </Col>
                    </Row>
                </DataCard>
            </Col>

            <Col span={8} >
                <DataCard style={{margin: 10}}>
                    <Row>
                        <Col className="text-center" style={{margin: "auto"}}>
                            <b>Carbon Credits Achieved</b>
                        </Col>
                    </Row>

                </DataCard>
            </Col>

            <Col span={8} >
                <DataCard style={{margin: 10}}>
                    <Row>
                        <Col className="text-center" style={{margin: "auto"}}>
                            <b>Safety</b>
                        </Col>
                    </Row>

                </DataCard>
            </Col>
        </Row>
    </div>
}
