import React from "react";
import axios from "axios";
import {properties} from "./properties";
import * as Auth from "./AuthService";
import {Button, Image} from "react-bootstrap";
import './index.css'

class PasswordCreate extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            password: '',
            newPassword:''
        }
    }


    changePassword(){
        if(this.state.password.length >= 8 && this.state.newPassword === this.state.password) {
            this.setState({isLoading: true});

            let data = {
                email: new URLSearchParams(window.location.search).get('email'),
                password: this.state.password,
                id: new URLSearchParams(window.location.search).get('id')
            };

            axios.post(`${properties.apiUrl}/api/user/password_create`,data, Auth.createConfig())
                .then((json) => {
                    alert('Your password was created! You can now use your credentials to enter the system');
                    window.location.href = '/login'
                })
                .catch(error => {
                        if(error.response.data.msg){
                            alert(error.response.data.msg)
                        } else {
                            alert(error.response.data)
                        }
                    }
                )
                .finally(() => this.setState({
                    isLoading: false
                }));
        } else {
            if(!(this.state.password.length >= 8)){
                alert('Password length couldn\'t be less than 8 symbols')
            } else if(this.state.newPassword !== this.state.password){
                alert('Your passwords doesn\'t match')
            }
        }
    }

    componentDidMount() {
        let email = new URLSearchParams(window.location.search).get('email');
        let id = new URLSearchParams(window.location.search).get('id');
        axios.get(`${properties.apiUrl}/api/check?email=${email}&id=${id}`, Auth.createConfig())
            .then(json => this.setState({loading: false}))
            .catch(error => {
                alert(error.response.data);
                window.location.href = '/search'
            });
    }

    render() {
        return (
            this.state.loading ?
                <div className="Account-root Login-root center-block panel panel-primary">
                    <Image src="/images/digatex.png"/>
                    <h3>Redirecting
                        <Image src="/images/search-loader.gif"/>
                    </h3>
                </div> :
                <div className="Account-root Login-root center-block panel panel-primary">
                    <Image src="/images/digatex.png"/>
                    <div>Enter your new password:
                        <input
                            className='required'
                            value={this.state.newPassword}
                            type='password'
                            onChange={event => this.setState({newPassword: event.target.value})}/>
                    </div>
                    <div className='required'>Confirm your new password:
                        <input
                            value={this.state.password}
                            type='password'
                            onChange={event => this.setState({password: event.target.value})}/>
                    </div>
                    <div>
                        <Button style={{marginTop: 10}} type="submit" bsStyle="primary" className="center-block"  onClick={() => this.changePassword()} loading={this.state.isLoading}>Confirm password</Button>
                    </div>
                </div>
        );
    }

}

export default PasswordCreate;
