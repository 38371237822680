import React from "react";
import { Line } from 'react-chartjs-2';
import {properties} from "../../properties";


export function Graph ({data, labels, colors, legendFields, height, titleLabels}) {

    const defaultColors = [
        'rgba(0, 127,197, 0.5)',
        'rgba(0, 255, 128, 0.2)',
        'rgba(54, 162, 235,00.2)'
    ]

    const use = colors || defaultColors

    const columnNames = titleLabels || []

    const options = {
        interaction: {
            intersect: false,
            axis: 'x'
        },
        scales: {
            y: {
                title: {
                    display: columnNames.length> 0,
                    text: columnNames[1],
                    color: properties.kbrColors.pantone301Blue
                },
            }, x: {
                title: {
                    display: columnNames.length > 0,
                    text: columnNames[0],
                    color: properties.kbrColors.pantone301Blue
                },
            }
        },
        height,
    };


    const getTransactionsChartData = () => {
        return {
            labels: data.map(s => s['date']),
            datasets: labels.map((v, i) => {
                return {
                    label: legendFields[i],
                    data: data.map(s => s[v]),
                    borderColor: use[i],
                    backgroundColor: use[i],
                    fill: false,
                }
            })
        };
    }

    return (
        <Line style={{height: height}} data={getTransactionsChartData()} options={options} />
    )

}
