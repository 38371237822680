import React, {Component} from "react";
import {Col, Row, Select} from "antd";
import {CardWith2ParamsAndGraph} from "../components/helpers/cards/CardWith2ParamsAndGraph";
import {CardWithLineChart} from "../components/helpers/cards/CardWithLineChart";
import {properties} from "../properties";
import api from "../api";
import * as Auth from '../AuthService'
import {getProjectId} from "../helpers";
import {Option} from "antd/es/mentions";
import {TitleBlock} from "../components/helpers/TitleBlock";
import {CardWithHistoryCharts} from "../components/helpers/cards/CardWithHistoryCharts";

export class DocumentAnalysis extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: {
                unique: 0,
                total: 0,
                processed: 0,
                finalised: 0,
                locked: 0,
                tags: 0,
                date: ''
            },
            results: [],
            dates: [],
            selectedDate: ''
        }
    }

    prepareDataForGraph = () => {
        const labels = ['processed','finalised', 'locked' ]
        const data = this.state.selected
        const result = []
        for (const label of labels ) {
            result.push({
                title: label,
                value: data[label],
                percentage: data.total > 0 ? Math.round(data[label] / data.total * 100 ) : 0
            })
        }
        return result
    }

    getResults = () => {
        const project_id = getProjectId(this.props.location)
        api.get(`/projects/${project_id}/document-analysis/results`, Auth.createConfig())
            .then(json => this.setState({results: json.data}))
    }

    getDates = () => {
        const project_id = getProjectId(this.props.location)
        api.get(`/projects/${project_id}/document-analysis/dates`, Auth.createConfig())
            .then(json => {
                this.setState({dates: json.data, selectedDate: json.data[json.data.length - 1]._id})
            })
    }

    handleChange = value => {
        this.setState({selectedDate: value})
    }

    getResultById = id => {
        const project_id = getProjectId(this.props.location)
        api.get(`/projects/${project_id}/document-analysis/result/id?_id=${id}`, Auth.createConfig())
            .then(json => this.setState({selected: json.data}))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.selectedDate !== this.state.selectedDate) {
            this.getResultById(this.state.selectedDate)
        }
    }

    componentDidMount() {
        this.getResults();
        this.getDates();
    }

    render() {

        const docsColors = [
            properties.kbrColors.yellow,
            properties.kbrColors.vividCeruleanBlue,
            properties.kbrColors.acajouMarron,
            properties.kbrColors.green
        ]

        const chartColors = [
            properties.kbrColors.green,
            properties.kbrColors.acajouMarron,
            properties.kbrColors.vividCeruleanBlue,
            properties.kbrColors.yellow,

        ]

        const tagsColors = [
            properties.kbrColors.pantone301Blue,
            properties.kbrColors.lightBlue,
        ]

        const dropdown = <Select style={{width: 200}} value={this.state.selectedDate} onSelect={this.handleChange}>
            {this.state.dates.map((v,i) => <Option key={i} value={v._id}>{v.date}</Option>)}
        </Select>

        return (
            <div>
                <Row>
                    <Col span={1} />
                    <Col span={12}>
                        <Row>
                    <Col span={24} style={{padding: 10}}>
                        <TitleBlock title={`Status on: ${this.state.selected.date}`} />
                    </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Row>
                                    <Col span={24}>
                                        <CardWithLineChart colors={chartColors}
                                                                 height={355}
                                                                 cardTitle={'Documents Statistics'}
                                                                 showLegend={true}
                                                                 data={this.prepareDataForGraph()}
                                                                 total={this.state.selected.total} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <CardWith2ParamsAndGraph colors={tagsColors}
                                                                 suffix={''}
                                                                 doughnutHeight={65}
                                                                 full={false}
                                                                 showLegend={true}
                                                                 titles={['unique', 'Total']}
                                                                 labels={['Unique', 'Total']}
                                                                 value={this.state.selected}
                                                                 cardTitle={'Tags Statistics'}
                                                                 total={this.state.selected.tags} />
                                    </Col>
                                </Row>
                                <Row>

                                </Row>
                            </Col>

                        </Row>
                    </Col>
                    <Col span={10} style={{padding: 10}}>
                        <CardWithHistoryCharts titles={[<div>History Overview <div style={{float: "right"}}>{dropdown}</div></div>, 'Documents Procession dynamics', 'Tags Extraction dynamics']} graph1={{
                            data: this.state.results,
                            colors: docsColors,
                            columnNames: ['Dates', '№ of Documents'],
                            legendFields: ['Locked','Finalised', 'Processed', 'Total'],
                            labels:['locked','finalised', 'processed', 'total']
                        }} graph2={{
                            data: this.state.results,
                            colors: tagsColors,
                            columnNames: ['Dates', '№ of Tags'],
                            legendFields: ['Unique', 'Total'],
                            labels:['unique', 'tags']
                        }} />
                    </Col>
                </Row>
            </div>
        );
    }

}
