import React, {Component} from "react";
import {ListOfMaterials} from "../components/ListOfMaterials";
import {createInsuranceLink, createMINMAxLink, getProjectId} from "../helpers";
import {Tabs} from "antd";
const { TabPane } = Tabs;

export class InsuranceItems extends Component {

    render() {

        const createLink = (material, from) => {
            return createInsuranceLink(material, getProjectId(this.props.location), from)
        }

        return (
            <div style={{padding: 24}}>
                <Tabs>
                    <TabPane tab={'Materials'} key={"2"}>
                        <ListOfMaterials baseUrl={`/projects/${getProjectId(this.props.location)}/insurance-materials`} createLink={createLink}/>
                    </TabPane>
                </Tabs>
            </div>
        );
    }

}
