import 'antd/dist/antd.css';
import './Dashboard.css';
import {connect} from 'react-redux'
import React from "react";
import {Col, Divider, Layout, message, Row} from 'antd';
import {
    getDuplicatesItems,
    getOverviewInfo,
    getInsuranceItems, getNoBOMItems,
    getFilterValues,
    filter,
    getFullItems,
    getObsoleteItems,
    getSlowMovingItems,
    searchByMaterial, getSelectedExamples
} from "./actions/Requests";
import {Content} from "antd/es/layout/layout";
import {downloadExcel, openHalfLink} from "./helpers";
import {InfoPanel} from "./components/helpers/InfoPanel";
import {TablePanel} from "./components/helpers/TablePanel";
import {properties} from "./properties";
import * as Auth from "./AuthService";
import axios from "./api";


class DashboardPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filterMode: true,
            loading: false,
            pagination: {}
        }
    }


    componentDidMount() {
        const panels = ['full', 'duplicates', 'obsoletes', 'insurance', 'slow-moving', 'no-bom']
        for (const element of panels) {
            this.props.getOverviewInfo(element)
        }



    }

    componentDidUpdate(prevProps) {
        if (prevProps.selecmtedTitle !== this.props.selectedTitle) {
                this.props.getFilterValues(this.state.title, this.props.selected.coluns)
        }

        if (prevProps.selectedTitle !== this.props.selectedTitle) {
            this.setState({filterMode: true})
        }
    }

    handleClick(action, title='') {
        this.setState({title: title, filterMode: false})
        action("full", {current: 1, pageSize: 50})
    }

    getType = () => {
        switch (this.props.selectedTitle) {
            case 'Duplicates':
                return 'duplicate'
            case 'Obsoletes':
                return 'obsoletes'
            case 'Insurance Items':
                return 'insurance'
            case 'No BOM Items':
                return 'no-bom'
            case 'Slow Moving Items':
                return 'slow-moving'
            case 'Client Inventory Info':
                return 'full'

            default:
                return this.props.selectedTitle
        }
    }

    search = (query) => {
        if (query) {
            this.props.searchByMaterial(query, this.getType())
        }
        else {
            this.props.getFullItems()
        }
    }

    render() {

        const project_id = this.props.location.pathname.split('/')[2]

        const search = (query) => {
            if (query) {
                this.props.searchByMaterial(query, "full")
            }
            else {
                this.props.getSelectedExamples("full", {current: 1, pageSize: 50})
            }
        }

        const _export = () => {
            message.info('Start exporting the category items')
            this.setState({loading: true})
            const project_id = window.location.pathname.split('/')[2]
            const config = Auth.createConfig();
            config['responseType'] = 'blob';
            axios.get(`/projects/${project_id}/export/${"full"}`, config)
                .then(json => {
                    downloadExcel(json.data, `${"full"} items.xlsx`)
                }).catch(error => {
                console.log(error)
            }).finally(() => this.setState({loading: false}))
        }

        const handleChange = (pagination, filters, sorters) => {
            this.setState({pagination: pagination})
            this.props.getSelectedExamples("full", pagination, filters, sorters)
        }

        return (
            <Layout>
                <Content>

                    <div className='text'>
                        <Row>
                            <Col span={7} />
                            <Col span={10} onClick={() => this.props.getSelectedExamples("full", {current: 1, pageSize: 50})}>
                                <InfoPanel selected={this.props.selectedTitle}
                                           title={<div>
                                               <div>Latest Status for Client Inventory Info</div>
                                           </div>
                                }
                                           info={this.props.inventoryInfo}/>
                            </Col>
                            <Col span={7} />
                        </Row>
                        <Row style={{padding: 10}}>
                        <Divider type='horizontal' orientation='center' style={{opacity: 0.5, backgroundColor: properties.kbrColors.pantone301Blue}} />
                        </Row>
                        <Row>
                            <Col span={8} onClick={() => openHalfLink('commonality', project_id)}>
                                <InfoPanel selected={this.props.selectedTitle}
                                           searched={this.props.selected.data && this.props.selected.data.length === 1 && this.props.selected.data[0]['Duplicate'] === 'YES'}
                                           title="Duplicates"
                                           info={this.props.duplicatesInfo}/>
                            </Col>


                            <Col span={8} onClick={() => openHalfLink('insurance', project_id)}>
                                <InfoPanel title="Insurance Items"
                                           selected={this.props.selectedTitle}
                                           searched={this.props.selected.data && this.props.selected.data.length === 1 && this.props.selected.data[0]['Insurance'] === 'YES'}
                                           info={this.props.insuranceInfo}/>
                            </Col>

                            <Col span={8} onClick={() => openHalfLink('no-bom', project_id)}>
                                <InfoPanel title="No BOM Items"
                                           searched={this.props.selected.data && this.props.selected.data.length === 1 && this.props.selected.data[0]['EQ BOM Count'] === 0}
                                           selected={this.props.selectedTitle}
                                           info={this.props.noBOMInfo}/>
                            </Col>
                        </Row>
                    <Row>
                        <Col span={12} onClick={() => openHalfLink('obsoletes', project_id)}>
                            <InfoPanel title="Obsoletes"
                                       selected={this.props.selectedTitle}
                                       searched={this.props.selected.data && this.props.selected.data.length === 1 && this.props.selected.data[0]['PotOb'] === 'YES'}
                                       info={this.props.obsoleteInfo}/>
                        </Col>
                        <Col span={12} onClick={() => openHalfLink('slow-moving', project_id)}>
                            <InfoPanel title="Slow Moving Items"
                                       searched={this.props.selected.data && this.props.selected.data.length === 1 && this.props.selected.data[0]['Type'] === 'Slow Moving'}
                                       selected={this.props.selectedTitle}
                                       info={this.props.slowMovingInfo}/>
                        </Col>
                    </Row>
                    </div>

                    {this.props.selected && this.props.selected.data &&  <Layout className="site-layout" style={{minHeight: 555}}><div style={{margin: 10, maxHeight: 300}}>
                        <Row>
                            <TablePanel pagination={this.state.pagination} total={this.props.total} export={_export} search={search} data={this.props.selected} handleChange={handleChange}/>
                        </Row>
                    </div></Layout>}
                </Content>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        inventoryInfo: state.inventoryInfo,
        duplicatesInfo: state.duplicatesInfo,
        obsoleteInfo: state.obsoleteInfo,
        insuranceInfo: state.insuranceInfo,
        slowMovingInfo: state.slowMovingInfo,
        noBOMInfo: state.noBOMInfo,
        selected: state.selected,
        total: state.total,
        selectedTitle: state.selectedTitle,
        lastCategorySelected: state.lastCategorySelected,
        filterValues: state.filterValues
    }
}

export default connect(mapStateToProps, {
    getOverviewInfo,
    getFilterValues,
    filter,
    getSelectedExamples,
    getFullItems,
    getDuplicatesItems,
    getObsoleteItems,
    getInsuranceItems,
    getSlowMovingItems,
    getNoBOMItems,
    searchByMaterial
})(DashboardPanel)
