
export const GET_DUPLICATES_INFO = 'GET_DUPLICATES_INFO'
export const GET_OBSOLETE_INFO = 'GET_OBSOLETE_INFO'
export const GET_INSURANCE_INFO = 'GET_INSURANCE_INFO'
export const GET_SLOW_MOVING_INFO = 'GET_SLOW_MOVING_INFO'
export const GET_NO_BOM_INFO = 'GET_NO_BOM_INFO'
export const GET_INVENTORY_INFO = 'GET_INVENTORY_INFO'

export const SELECT_ITEMS = 'SELECT_ITEMS'
export const SELECT = 'SELECT'
export const LAST_CATEGORY_SELECTED = 'LAST_CATEGORY_SELECTED'
export const FILTER_VALUES = 'FILTER_VALUES'

export const SELECT_PROJECT = 'SELECT_PROJECT'

export const SELECTED_INFO = 'SELECTED_INFO'

export const TOTAL_QUANTITY = 'TOTAL_QUANTITY'
