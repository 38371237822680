import React from 'react';
import {Layout, Menu} from 'antd';
import {DashboardHeader} from "../../components/header/DashboardHeader";

export class ProjectsLayout extends React.Component {

  componentDidMount() {
    document.title = 'Workbench'
  }

  render() {
    let selectedMenuItem
    switch (this.props.location.pathname.split('/')[1]) {

      case 'projects':
        selectedMenuItem = 'projects'
        break;
      case 'users':
        selectedMenuItem = 'users'
        break;
      default:
        selectedMenuItem = 'menu'
    }

    return (
      <Layout  style={{height:"100vh"}}>
        <DashboardHeader />
        <Layout>
          <Layout.Sider width={'20%'} theme='light'>
            <Menu theme="light" mode="inline" selectedKeys={[selectedMenuItem]}>
              <Menu.Item key="projects">
                <a href='/projects'>
                  Projects
                </a>
              </Menu.Item>
              {/*{showExtraFeatures &&*/}
              {/*<Menu.Item key="users">*/}
              {/*  <a href='/users'>*/}
              {/*    Users*/}
              {/*  </a>*/}
              {/*</Menu.Item>*/}
              {/*}*/}
            </Menu>
          </Layout.Sider>

          <Layout.Content style={{width: '75%', padding: '24px'}}>
            {this.props.children}
          </Layout.Content>
        </Layout>
      </Layout>
    ) 
  }
}
