import React, {Component} from "react";
import {getProjectId, labelMapper} from "../../helpers";
import {Col, Descriptions, Layout, Row, Statistic, Table} from "antd";
import './settings.css'
import {ParamsModal} from "../helpers/modals/ParamsModal";
import {FullHeightCard} from "../helpers/cards/FullHeightCard";
import api from "../../api";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {TableModal} from "../helpers/modals/TableModal";

export function DefaultTable ({dataSource, onEdit, onDelete, isAdmin, addColumns}) {

    const columns = [
        {title: <b>{labelMapper('production_output')}</b>,
            dataSource: 'production_output',
            key: 'production_output',
            render: (dataSource, item) => <b>{item.constants.production_output}</b>
        },
        ...addColumns,
        {title: <b>Actions</b>,
            render: (item, row) => (isAdmin && !row.isLast) && <div>
                    <EditOutlined className={'beautiful-icon'} onClick={() => onEdit(item)}/>
                    <DeleteOutlined className={'beautiful-icon'} style={{marginLeft: 10}} onClick={() => onDelete(item)}/>
                </div>
            }
    ]

    return <Table size={'small'} scroll={{y: 250}} columns={columns} dataSource={dataSource}  />
}

export function InsTable ({dataSource, best}) {

    let columns = [0,1,2,3,4,5,6,7,8,9,10].map(v=> {
            return {
                title: <b>{v}</b>,
                dataSource: `holding - ${v}`,
                key: `holding - ${v}`,
                render: (dataSource, item) => <div className={best === v  ? 'best-option' : ''}>
                    <Statistic valueStyle={{fontSize: 12}} value={item[`holding - ${v}`]} /></div>
            }
        }
    );

    columns = [
        {
            title: <b>Holding Quantity</b>,
            dataSource: `category`,
            key: `category`,
            render: (dataSource, item) => <b>{labelMapper(item[`category`])}</b>
        },
        ...columns
    ]

    const transposeDataSource = () => {
        const data = [...dataSource]
        if (data.length > 0) {
            const transposed = []
            const order = ['holding_quantity', 'stockout_cost', 'writeoff_cost', 'interest_cost', 'warehouse_cost', 'holding_cost', 'total_cost', 'part_availability']
            for (let key of order) {
                if (key === 'holding_quantity') {
                    continue
                }
                transposed.push({
                    category: key
                })
            }
            for (let i = 0; i < transposed.length; i++) {
                for (let y = 0; y < data.length; y++) {
                    transposed[i][`holding - ${y}`] = Math.round(data[y][transposed[i]['category']] *  10**4) / 10**4
                }
            }
            return transposed
        } else {
            return []
        }
    }

    return <Table size={'small'} columns={columns} dataSource={transposeDataSource()}  />
}


export class InsuranceSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            project: {},
            defaults: {
                constants: {}
            },
            initialValues: {
                'constants': {}
            },
            dataSource: [],
            isModalVisible: false,
            tableModalVisible: false,
            canEdit: false,
        }
    }



    componentDidMount() {
        const project_id = getProjectId(this.props.location)
        this.checkAdminPermission()
        this.getDefaults(project_id)
        this.getTableDefaults(project_id)
    }

    closeModal = () => {
        this.setState({isModalVisible: false})
    }

    closeTableModal = () => {
        this.setState({tableModalVisible: false, initialValues: {}})
    }

    saveValues = values => {
        const project_id = getProjectId(this.props.location)
        api.post(`/projects/${project_id}/save_insurance_defaults`, values)
            .then(json => {
                this.closeModal()
                this.getDefaults(project_id)
            })

    }

    saveTableRow = values => {
        const project_id = getProjectId(this.props.location)
        api.post(`/projects/${project_id}/insurance_table_defaults/update`, values)
            .then(json => {
                this.closeTableModal()
                this.getTableDefaults(project_id)
            })

    }

    getDefaults = project_id => {
        api.get(`/projects/${project_id}/insurance_defaults`)
            .then(json => this.setState({defaults: json.data}))
    }

    getTableDefaults = project_id => {
        api.get(`/projects/${project_id}/insurance_table_defaults`)
            .then(json => this.setState({dataSource: json.data}))
    }

    checkAdminPermission = () => {
        api.get('/check_permission?require=insurance-admin')
            .then(() => this.setState({canEdit: true}))
            .catch(() => this.setState({canEdit: false}))
    }

    handleDelete = item => {
        const project_id = getProjectId(this.props.location)
        api.get(`/projects/${project_id}/insurance_table_defaults/delete?_id=${item._id}`)
            .then(json => this.getTableDefaults(project_id))
    }

    handleEdit = item => {
        this.setState({
            initialValues: item,
            tableModalVisible: true
        })
    }

    render() {
        const values = [];
        for (let v of Object.keys(this.state.defaults.constants)) {
            let value = this.state.defaults.constants[v]
            values.push(<Descriptions.Item span={24} label={<b>{labelMapper(v)}</b>}>{value}</Descriptions.Item>)
        }

        const addColumns = [
            {title: labelMapper('loss'), dataSource: 'loss', key: 'loss', render: (dataSource, item) => item.constants.loss},
        ]

        return (
            <Layout>
                <Row style={{height: '100%'}}>
                    <Col span={12} style={{padding: 10, height: '92vh'}}>
                        <FullHeightCard title={<div>Params config {this.state.canEdit && <EditOutlined onClick={() => this.setState({isModalVisible: true})}/>}</div>} >
                            <Descriptions>
                                {values}
                            </Descriptions>
                        </FullHeightCard>
                    </Col>
                    <Col span={12} style={{padding: 10}}>
                        <FullHeightCard title={'Table params'}>
                            {this.state.canEdit && <div className='float-right beautiful-link'
                                  onClick={() => this.setState({tableModalVisible: true})}>Add new row</div>}
                            <DefaultTable addColumns={addColumns} dataSource={this.state.dataSource} onEdit={this.handleEdit} isAdmin={this.state.canEdit} onDelete={this.handleDelete} />
                        </FullHeightCard>
                    </Col>
                </Row>
                <ParamsModal isModalVisible={this.state.isModalVisible} closeModal={this.closeModal} initialValues={this.state.defaults.constants} save={this.saveValues} />
                {this.state.tableModalVisible && <TableModal isModalVisible={this.state.tableModalVisible} closeModal={this.closeTableModal}
                             initialValues={this.state.initialValues} save={this.saveTableRow}/>}
            </Layout>
        );
    }

}
