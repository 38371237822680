import 'react-app-polyfill/ie9';
import 'url-search-params-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';

import {Provider} from 'react-redux'
import configureStore from "./configureStore";
import {withRouter} from "react-router";
import {createBrowserHistory} from "history";

import {ConnectedRouter} from "connected-react-router";
import {AHRouter} from "./Router";
import {CurrentUserProvider} from "./contexts/AuthContext";
import {Auth, UIConfigContext} from "@digatex/digatex-ui-lib"
import './index.less';
import axios from "axios";
import {message} from "antd";
import {UI_CONFIG} from "./properties";

const history = createBrowserHistory()
const Router = withRouter(AHRouter)
export const store = configureStore()

axios.interceptors.request.use((config) => {
    if (Auth.isAuthenticated()) {
        const cb = () => {
            config.headers = { ...config.headers, ...Auth.headers()	}
            return Promise.resolve(config);
        };
        return Auth.updateToken(cb);
    }
});

axios.interceptors.response.use(
    response => response,
    error => {
        const status = error.response.status;
        if (status && (status >= 400 && status <= 599)) {
            const data = error.response.data;
            if (data.message != null) {
                message.error(data.message);
            }
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
);

Auth.init(() =>
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <UIConfigContext.Provider value={UI_CONFIG}>
                <CurrentUserProvider>
                    <Router />
                </CurrentUserProvider>
                </UIConfigContext.Provider>
            </ConnectedRouter>
        </Provider>,
        document.getElementById('root')
    ));
