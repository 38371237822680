import React, {Component} from "react";
import {Button, Col, Popover, Row} from "antd";
import {ImprovementStatistics} from "../components/helpers/ImprovementStatistics";
import {properties} from "../properties";
import {getProjectId} from "../helpers";
import api from "../api";
import * as Auth from "../AuthService";
import {TitleBlock} from "../components/helpers/TitleBlock";
import {CardWith2ParamsAndGraph} from "../components/helpers/cards/CardWith2ParamsAndGraph";
import {CardWithHistoryCharts} from "../components/helpers/cards/CardWithHistoryCharts";
import {SearchWithOptions} from "../components/SearchWithOptions";
import {FilterOutlined} from "@ant-design/icons";

export class BOMsPanel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            without: [],
            withBOM: [],
            last: {
                start: '',
                current: '',
                target: '',
                date: ''
            },
            isModalVisible: false,
            disciplines: [],
            checkedKeys: [],
            filter: []
        }
    }

    getResults = (filter) => {
        const project_id = getProjectId(this.props.location)
        api.post(`/projects/${project_id}/boms/results`, filter, Auth.createConfig())
            .then(json => {
                this.setState({
                    withBOM: json.data.stat,
                    last: json.data.stat[json.data.stat.length-1],
                    filter: json.data.filter.filter
                })
            })
    }

    getWithoutBOM = () => {
        const without = this.state.withBOM.map(v=> {
            return {
                start: v.total - v.start,
                target: v.total - v.target,
                current: v.total - v.current,
                date: v.date
            }
        })
        this.setState({
            without: without
        })
    }

    getDisciplines() {
        const project_id = getProjectId(this.props.location)
        return api.get(`/projects/${project_id}/disciplines`)
    }

    componentDidMount() {
        this.getResults({filter: ["all"]});
        this.getDisciplines().then(json => this.setState({disciplines: json.data}))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.withBOM !== this.state.withBOM) {
            this.getWithoutBOM()
        }
    }

    getImprovement = () => {
        if (this.state.withBOM.length > 0 ) {
            return Math.round(this.state.last.current / this.state.withBOM[0].total * 100) - Math.round(this.state.withBOM[0].current / this.state.withBOM[0].total * 100)
        }
        return 0
    }

    render() {

        const withBomsColors = [
            properties.kbrColors.yellow,
            properties.kbrColors.vividCeruleanBlue,
            properties.kbrColors.acajouMarron,
        ]

        const withoutBomsColors = [
            properties.kbrColors.yellow,
            properties.kbrColors.pantone301Blue,
            properties.kbrColors.acajouMarron,
        ]

        const legendFields = ['Start', 'Current', 'Target']
        const labels = ['start', 'current', 'target']

        const project_id = getProjectId(this.props.location)

        const openModal = () => {

            if (!this.state.treeData) {
                const treeData = [
                    {
                        title: 'Total',
                        key: '0',
                        children: this.state.disciplines.map(
                            v => {
                                return {
                                    title: `${v.discipline} [${v.size}]`,
                                    key: v._id,
                                    name: v.discipline,
                                    status: "discipline"
                                }
                            }
                        )
                    }
                ]
                setTreeData(treeData)
                setCheckedKeys(this.state.disciplines.map(v => v._id))
            }
            this.setState({isModalVisible: true, expandedKeys: ['0']})
        }



        const button =
            <Button style={{color: "white", backgroundColor: properties.kbrColors.pantone301Blue}} onClick={openModal}>
                <FilterOutlined color={"white"} />
            </Button>

        const setTreeData = treeData => {
            this.setState({treeData: treeData})
        }

        const createFilter = checked => {
            if (checked) {
                let filter = {}
                if (checked.filter(v => v.key === '0').length > 0) {
                    filter = ['all']
                } else {
                    for (let node of checked) {
                        if (node.status === 'discipline') {
                            if (!Object.keys(filter).includes(node.name)) {
                                filter[node.name] = []
                                if (!node.children) {
                                    filter[node.name] = ['all']
                                }
                            }
                        }
                        if (node.status === 'class') {
                            if (!Object.keys(filter).includes(node.discipline)) {
                                filter[node.discipline] = []
                            }
                            filter[node.discipline].push(node.title)
                        }
                    }

                }
                return filter;
            }
        }

        const setCheckedKeys = (checkedKeys, info) => {
            this.setState({checkedKeys: checkedKeys})
            if (info) {
                this.setState({filter:createFilter(info.checkedNodes)})
            }
        }

        const onLoadData = ({ key, children }) =>
            new Promise((resolve) => {
                if (children) {
                    resolve();
                    return;
                }
                api.get(`/projects/${project_id}/classes/${key}`).then(json => {
                    const tree = [...this.state.treeData];
                    const parent = tree[0].children.find(node => node.key === key)
                    parent['children'] = json.data.map(v =>{
                        return {
                            title: v,
                            isLeaf: true,
                            status: 'class',
                            discipline: parent.name,
                            key: `${parent.name}-class:${v}`,
                        }
                    })
                    setTreeData(tree)
                        resolve();
                    })
            });

        const updateTreeData = (list, key, children) =>
            list.map((node) => {
                if (node.key === key) {
                    return { ...node, children };
                }

                if (node.children) {
                    return { ...node, children: updateTreeData(node.children, key, children) };
                }

                return node;
            });

        const onOk = () => {
            this.getResults({filter: this.state.filter})
            this.setState({isModalVisible: false})
        }

        const beatifyFilter = () => {
            let text = ''
            let _popoverText = ''
            if (!this.state.filter) {
                 text = 'All classes are selected'
                _popoverText = <ul>{this.state.disciplines.map(v=>{
                    return <li><b>{v.discipline}</b></li>
                })}</ul>
            } else {
                _popoverText = []
                text = Object.keys(this.state.filter).map(d => {
                    if (this.state.filter[d].includes('all')) {
                        _popoverText.push(<li><b>{d}</b></li>)
                        return d
                    } else {
                        _popoverText.push(<li><b>{d}: </b> {this.state.filter[d].join(', ')}</li>)
                        return this.state.filter[d].join(', ')
                    }
                }).join(', ')
                _popoverText = <ul>{_popoverText}</ul>
            }

            const content = (
                <div style={{whiteSpace: "break-spaces" , width: 400}}>
                    {_popoverText}
                </div>
            );

            return <Popover content={content} title="Current Filters" placement={'rightTop'} ><span>
                {text}
            </span></Popover>
        }

        return (
            <div>
                <Row>
                    <Col span={1}/>
                    <Col span={12}>
                        <Row>
                            <Col span={24} style={{padding: 15}}>
                                <TitleBlock title={
                                    <div>
                                        <div>
                                        Status on: {this.state.last.date}
                                        </div>
                                        <div style={{textAlign: "left", marginTop: 10,  overflow: "hidden", textOverflow: "ellipsis", width: '100%', whiteSpace:"nowrap"}}>
                                            Current Filter {button}: {beatifyFilter()}
                                        </div>
                                    </div>
                                }/>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{padding: 5}}>
                                <Row>
                                    <Col span={12}>
                                        <CardWith2ParamsAndGraph colors={properties.startColors}
                                                                 height={260}
                                                                 doughnutHeight={90}
                                                                 showLegend={true}
                                                                 titles={['start', 'Total']}
                                                                 labels={['With BOM', 'Total']}
                                                                 value={this.state.last}
                                                                 cardTitle={'Start Values'}
                                                                 total={this.state.last.total}/>
                                    </Col>
                                    <Col span={12}>
                                        <CardWith2ParamsAndGraph colors={properties.targetColors}
                                                                 height={260}
                                                                 doughnutHeight={90}
                                                                 showLegend={true}
                                                                 labels={['With BOM', 'Total']}
                                                                 titles={['target', 'Total']}
                                                                 value={this.state.last}
                                                                 cardTitle={'Target Values'}
                                                                 total={this.state.last.total}/>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <CardWith2ParamsAndGraph colors={properties.currentColors}
                                                                 suffix={''}
                                                                 height={250    }
                                                                 doughnutHeight={90}
                                                                 showLegend={true}
                                                                 titles={['current', 'Total']}
                                                                 labels={['With BOM', 'Total']}
                                                                 improvement={<ImprovementStatistics value={this.getImprovement()}/>}
                                                                 value={this.state.last}
                                                                 cardTitle={'Current Status'}
                                                                 total={this.state.last.total}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={10} style={{padding: 15}}>
                        <CardWithHistoryCharts titles={['Overview', 'Materials with BOMs dynamics', 'Materials without BOMs dynamics']} graph1={{
                            data: this.state.withBOM,
                            colors: withBomsColors,
                            columnNames: ['Dates', 'Number of Materials', ],
                            legendFields,
                            labels
                        }} graph2={{
                            data: this.state.without,
                            colors: withoutBomsColors,
                            legendFields,
                            columnNames: ['Dates', 'Number of Materials', ],
                            labels
                        }} />
                    </Col>
                </Row>
                <SearchWithOptions title={'Detailed Overview'}
                                   isModalVisible={this.state.isModalVisible}
                                   project_id={project_id}
                                   checkedKeys={this.state.checkedKeys}
                                   setCheckedKeys={setCheckedKeys}
                                   treeData={this.state.treeData}
                                   onLoadData={onLoadData}
                                   onOk={onOk}
                                   onCancel={() => this.setState({isModalVisible: false})}/>
            </div>
        );
    }

}
