import React from "react";
import {Button, Col, Popover, Row, Table} from "antd";
import {properties} from "../../properties";
import {DataCard} from "./cards/Card";
import Search from "antd/es/input/Search";
import {MaterialBlock} from "./cards/MaterialBlock";
import Highlighter from "react-highlight-words";
import {createMINMAxLink, openLinkInNewTab} from "../../helpers";

export class SearchComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            query: ''
        }
    }


    search = () => {
        this.props.search(this.state.query)
    }

    handleChange = event => {
        this.setState({query: event.target.value})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.query !== this.state.query && this.state.query === '') {
            this.props.search('')
        }
    }

    render() {
        return (
            <div style={{float: 'right', marginTop: -20, marginBottom:10}}>
                <Search placeholder='Search by material... '
                        value={this.state.query}
                        onChange={this.handleChange}
                        loading={false}
                        allowClear
                        onSearch={this.search}
                        enterButton />
            </div>
        );
    }

}

export class TablePanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filteredInfo: {},
            loading: false,
            lastQuery: ''
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filterValues !== this.props.filterValues) {
            const filters = this.getFilters()
            let result = {}
            for (let key in filters) {
                result[key] = filters[key].map(v => v['value'])
            }

            this.setState({filteredInfo: result, loading: false})
        }
        if(prevProps.data.data !== this.props.data.data ) {
            this.setState({loading: false})
        }
    }

    handleChange = (pagination, filters, sorters) => {
        this.setState({
            filteredInfo: filters,
            loading: true,
        });
        this.props.handleChange(pagination, filters, sorters);
    };

    getFilters() {
        const filters = {}
        for (let _key in this.props.filterValues) {
            filters[_key] = []
            this.props.filterValues[_key] = this.props.filterValues[_key].sort()
            for (let i in this.props.filterValues[_key]){
                filters[_key].push({
                    text: this.props.filterValues[_key][i],
                    value: this.props.filterValues[_key][i]
                },)
            }
        }
        return filters
    }

    render() {

        const data = this.props.data.data;
        const _columns = this.props.data.columns;


        let { filteredInfo } = this.state;
        filteredInfo = filteredInfo || {};

        const filters = this.getFilters()

        const columns = [];

        for (let key in _columns) {
            if (_columns[key] === 'description') {
                columns.push({
                    key: _columns[key],
                    title: 'Description',
                    dataIndex: _columns[key],
                })
            } else if (_columns[key] === 'Material') {
                columns.push({
                    key: _columns[key],
                    title:_columns[key] ,
                    width: 100,
                    dataIndex: _columns[key],
                    render: text => <MaterialBlock material={text}><Highlighter
                        style={{color: properties.kbrColors.pantone301Blue, cursor:'pointer'}}
                        onClick={() => openLinkInNewTab(createMINMAxLink(text, window.location.pathname.split('/')[2], window.location.href))}
                        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                        searchWords={[this.state.lastQuery]}
                        autoEscape
                        textToHighlight={text ? text.toString(): null}
                    /></MaterialBlock>
                })
            } else if (_columns[key] === 'Unit$') {
                columns.push({
                    key: _columns[key],
                    title: 'Unit ($)' ,
                    width: 100,
                    dataIndex: _columns[key],
                })
            } else if (_columns[key] === 'Trans 4Y') {
                columns.push({
                    key: _columns[key],
                    title: 'Trans 4Y' ,
                    width: 100,
                    dataIndex: _columns[key],
                    filters: filters[_columns[key]],
                    filteredValue: filteredInfo[_columns[key]] || null,
                })
            }  else if (_columns[key] === 'Trans PA (ave)') {
                columns.push({
                    key: _columns[key],
                    title: _columns[key] ,
                    width: 100,
                    dataIndex: _columns[key],
                    filters: filters[_columns[key]],
                    filteredValue: filteredInfo[_columns[key]] || null,
                })
            } else if (_columns[key] === 'EQ BOM Count') {
                columns.push({
                    key: _columns[key],
                    title: _columns[key] ,
                    width: 125,
                    dataIndex: _columns[key],
                    filters: filters[_columns[key]],
                    filteredValue: filteredInfo[_columns[key]] || null,
                })
            } else if (_columns[key] === 'Planned Deliv. Time') {
                columns.push({
                    key: _columns[key],
                    title: _columns[key] ,
                    width: 125,
                    dataIndex: _columns[key],
                })
            } else if (_columns[key] === 'Current SOH') {
                columns.push({
                    key: _columns[key],
                    title: _columns[key],
                    width: 100,
                    dataIndex: _columns[key],
                    filters: filters[_columns[key]],
                    filteredValue: filteredInfo[_columns[key]] || null,
                })
            } else {
                columns.push({
                    key: _columns[key],
                    title: _columns[key],
                    dataIndex: _columns[key],
                })
            }
        }

        columns.push({
            key: 'open in IH',
            title: 'Open in IH',
            dataIndex: 'open in IH',
            width: 100
        })

        for (let key in data){
            data[key]['open in IH'] = <a target="_blank"
                                         rel="noopener noreferrer"
                                         href={`${properties.ihLink}/?search=` + data[key]['Material']}>Open</a>
            data[key]['description'] = <Popover content={<div style={{width: 350}}>{data[key]['Material Long Text']}</div>}
                                                trigger="hover">
                <span style={{fontSize:12}}>{data[key]['Material Description']}</span>
            </Popover>

        }

        return (
            <Row>
                {data && <Col span={24}>
                    <DataCard style={{padding: 5, height: 510}}>
                        <Row>
                            <Col span={2} >
                                {this.props.selected !== 'Client Inventory Info' && <Button style={{float: 'right',marginTop: -20, marginBottom:10}} onClick={() => this.props.export()} className='button'>Export report</Button>}
                            </Col>
                            <Col span={22}>
                                <SearchComponent search={(query) => {
                                    this.setState({lastQuery: query ? query : ''})
                                    this.props.search(query)
                                }}/>
                            </Col>
                        </Row>
                        <StandardTable currentPage={this.props.pagination.current} pageSize={this.props.pagination.pageSize} total={this.props.total} data={data} columns={columns} loading={this.state.loading}
                                       onChange={this.handleChange}/>
                    </DataCard>
                </Col>}
            </Row>
        );
    }

}

export function StandardTable ({total, columns, data, loading, scroll, currentPage, tableSize, pageSize, onChange, tableProps}) {

    return <Table columns={columns} dataSource={data} {...tableProps}
                  size={tableSize}
                  loading={loading}
                  onChange={onChange}
                  pagination={
                    {
                           total: total,
                           current: currentPage,
                           pageSize: pageSize ? pageSize : 50
                    }
                  }
                  scroll={scroll ? {y: scroll} : {y: 300}}/>
}
