import {ModalWindow} from "./ModalWindow";
import React from "react";

export function HelpModal({isModalVisible, setModalVisible}) {

    return <ModalWindow title={'MIN/MAX Calculations Guide'}
                        isModalVisible={isModalVisible}
                        onOk={() => setModalVisible(false)}
                        scrollVisible={false}
                        onCancel={() => setModalVisible(false)}>
        <div>
            The KBR SIMS Workbench enables the calculation of MIN/MAX values as per calculations in this
            section and displays the existing against the proposed min/max levels. The assumptions are clearly
            displayed. The calculations form part of the DIGATEX DI-analytics platform and are invoked from the
            KBR SIMS Workbench.

            <div style={{marginTop: 20}}> The formulas outlined below are used by the KBR SIMS Workbench standard to calculate MIN/MAX
                levels for high turnover items:
            </div>
            <div style={{marginTop: 20, marginBottom: 20}}><code>MIN = (SS + DDLT)</code> where <code>SS = CSF x MAD x SQRT(LT)</code> and <code>DDLT = (LT x monthly demand)</code></div>
            <div><b>SS</b> = Safety Stock</div>
            <div><b>DDLT</b> = Demand during lead time</div>
            <div><b>CSF = Customer Service Factor</b> – should be inputted by user</div>
            <div><b>Service Level</b> and <b>CSF</b> is calculated from the table provided</div>
            <div><code>MAD = 1 x monthly demand</code> (Used 1 = very low volatility; can be calculated using consumption history)</div>
            <div><b>LT</b> – Lead time (divided by 30 days per month where LT > 0; otherwise √3.6 where LT = 0 )</div>
            <div style={{marginTop: 20, marginBottom: 20}}><code>MAX = MIN + ROQ</code> where <code>ROQ = SQRT((2AS)/i$)</code> where</div>
            <div><b>ROQ</b> = Reorder Quantity</div>
            <div><b>A</b> = annual demand quantity</div>
            <div><b>i</b> = holding cost in percentage terms (used 20%)</div>
            <div><b>S</b> = cost to place one order - should be inputted by user</div>
            <div><b>$</b> = per unit price</div>
        </div>

    </ModalWindow>
}
