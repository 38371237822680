import React, {useEffect} from "react";
import '../components/helpers.css'
import {Card, Col, Image, Row, Tooltip, Typography} from "antd";
import {openLinkInNewTab} from "../helpers";
import {OWNER, properties} from "../properties";

function LandingCard({product, url, height}) {
    return <Card style={{borderColor: properties.kbrColors.pantone301Blue, height, borderRadius: 15, cursor: 'pointer'}}
                 onClick={() => openLinkInNewTab(url)}
                 className='landing-links flex-center-vertically'>
        <div  style={{textAlign: 'center'}}>
            {OWNER === 'digatex' && <div>{product.icon && <Image src={product.icon} style={{height: 60}}/>}</div>}
        <Typography.Title level={4} style={{color: properties.kbrColors.pantone301Blue, fontSize: 24}}>
            <Tooltip title={url}>
                <span>{product.name}</span>
            </Tooltip>

        </Typography.Title>
            <ul style={{textAlign: 'left'}}>
                {product.include && product.include.map(f => <li>{f}</li>)}
            </ul>
        </div>
    </Card>
}

export function LandingPage() {
    useEffect(() => {
        document.title = 'Landing Page'
    }, []);
    const modules = ['ihLink', 'dmLink', 'analyticsLink', 'assetHubLink', 'khLink']
    const names = [{name: 'Inventory', icon: '/images/ih_icon.png', include: ['Consolidate Material Master data', 'Display transactions, BOM\'s etc associated with each MM item']},
        {name: 'Engineering', icon: '/images/engh_icon.png',include: ['Class Library', 'Data Mapper', 'Source Matrix', 'Registers']},
        {name: 'Workbench', include: ['Display potential savings option', ' MIN MAX scenarios', 'Insurance Spares', 'Calculation settings']},
        {name: 'Equipment', include: ['Configure: FLOC\'s, BOM\'s, Materials, Construction Types, PM Plans etc']},
        {name: 'Knowledge', icon: '/images/kh_icon.png', include: ['Provide intelligently searchable documents', 'Create equipment hierarchies', 'Mark up drawings e.g. corrosion loops, SIL Levels, criticality']}]

    const columns_span = 6
    const span_1 = Math.floor((24 - columns_span * 2) / 2)
    const span_2 = Math.floor((24 - columns_span * 3) / 2)

    return <div style={{margin: 'auto'}}>
        <Row style={{margin: 'auto'}}>
            <Col span={span_1} />
            <Col xxl={columns_span} xl={columns_span} lg={columns_span}  md={columns_span}  sm={columns_span*2}><LandingCard height={215} product={names[2]} url={properties[modules[2]]} /></Col>
            <Col xxl={columns_span} xl={columns_span} lg={columns_span}  md={columns_span}  sm={columns_span*2}><LandingCard height={215} product={names[3]} url={properties[modules[3]]} /></Col>

        </Row>
        <Row style={{margin: 'auto'}}>
            <Col span={span_2} />
            <Col xxl={columns_span} xl={columns_span} lg={columns_span}  md={columns_span}  sm={columns_span*2}><LandingCard height={285} product={names[4]} url={properties[modules[4]]} /></Col>
            <Col xxl={columns_span} xl={columns_span} lg={columns_span}  md={columns_span}  sm={columns_span*2}><LandingCard height={285} product={names[1]} url={properties[modules[1]]} /></Col>
            <Col xxl={columns_span} xl={columns_span} lg={columns_span}  md={columns_span}  sm={columns_span*2}><LandingCard height={285} product={names[0]} url={properties[modules[0]]} /></Col>
        </Row>
    </div>

}
