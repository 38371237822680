import React, {Component} from "react";
import {ListOfMaterials} from "../components/ListOfMaterials";
import {createMINMAxLink} from "../helpers";


export class MaterialsSearch extends Component {

    render() {

        const createLink = (material, from) => {
            return createMINMAxLink(material, this.props.projectId, from)
        }

        return (
            <ListOfMaterials baseUrl={`/projects/${this.props.projectId}/materials`} createLink={createLink}/>
        );
    }

}
